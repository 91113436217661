import { Injectable } from '@angular/core';

import { ActivatedRoute , Router } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { File, DirectoryEntry } from '@ionic-native/file/ngx';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MatomoInjector } from 'ngx-matomo';
import { MatomoTracker } from 'ngx-matomo';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { LoadingController, AlertController } from '@ionic/angular';
import { WebView } from '@ionic-native/ionic-webview/ngx';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { SecureStorageEcho, SecureStorageEchoObject } from '@ionic-native/secure-storage-echo/ngx';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';

import { EventsService } from './events-service.service';
import { Quote, toTypeScript } from '@angular/compiler';
//import { UpdateService } from './update.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  // Note that for now PRIVATE site is set on https://gregr2app.whitesite.lab.cm3cms.com URL 
  // For private site one can use https://whiteapp.whitesite.dev.cm3cms.com
  // Note that you may need to update your host file to access those sites when on office VPN !!!
  public sourceHost = 'https://marketplace.connectautoparts.dev.ddsn.net'; //'https://marketplace.connectautoparts.com.au'; //'https://marketplace.connectautoparts.dev.ddsn.net'; 'https://marketplace.connectautoparts.com.au'; 
  public appTextId = "marketplace"; 
  private serverApiUrl = "";
  private serverApiUrl2 = "";
  public serverApiUrl2A = "";
  public WebAppVersion: string = "0.8.0.1";

  // set of variables below will be overwritten from values coming from app "Module Settings" aricles on sourceHost server!
  public staticPageMap = {};
  public appSiteHost = '';
  public appType = 'private'; // 'private' or 'public'
  public forcePrivateAndroidAppToSetScreenLock = true; // when true then Android users must set screen lock before authentication otherwise encryption key will be stored and read from the server
  // note that dev analytics server 'https://old.intranet.ddsn.com/piwik/' is not accessible any more (:)
  public matomoUrl = 'https://analytics.ddsn.net/'; 
  public matomoSiteId: number = 57;  // 54 is for live Marketplace & 57 for test
  public passwordAuthenticationInBrowserEnabled = true;
  private useFirebase = false;
  private trackFirebaseAnalytics = false;
  public trackMatomoAnalytics = true;
  public sessionTimeInMinutes: number = 10; // time before app PIN authentication session expires
  public passwordSessionTimeInMinutes: number = 600; // time before browser u/p authenticated session expires
  private errorLogMaxEntriesToKeep = 200;
  private errorLogMinLogLevelToKeep = 1;
  public checkForUpdatesTimeInMinutes: number = 10; //90; // minimum time between checking for updates  
  public homePageNotificationsLimit = 2;
  // logDisplayLevel is used to change what one can see on console when app is running
  // level 0 - is for all data, 1 - basic data, 2 - errors & important info, 3 - nothing 
  // change it to 2 or 3 for live app
  public logDisplayLevel = 1;

  private firebaseHasPermissions = false;
  public currentVersionIsOld = false;
  public oldVersionBehaviourDefaultId = 'DoNothing'; // 'DoNothing', 'InformOnStart', 'SomeFunctionsMayNotWork', 'ForceUpdate'
  public oldVersionBehaviourMessages = {
    'InformOnStart': 'Newer version of App was published. Please update it.',
    'SomeFunctionsMayNotWork': 'Your App version is old and not supported. Some functions may stop working or do not function correctly. App update is strongly suggested',
    'ForceUpdate': 'Your App version requires update.'};

  public lastClickedUrl = "";
  public previousUrl = "";
  public lastClickedUserRole = "";
  public lastClickedUserId = "";
  public currentUserRole = "";
  public userIsAdmin: boolean = false;
  public switchRolesEnabled = false;
  public passwordAuthentication = false;
  private firebase: any = null;
  public appsettings: any = {};
  public companyName: string = 'your organisation';
  public userToken: string='';
  public deviceId: string='';
  public currentPin: string='';
  public currentPrivateKey: string='';
  public currentActivationEmail: string='';
  public currentCryptoKey: string='';
  public coremodulesdata: any = null;
  public othermodulesdata: any = null;
  public updateAllModules: boolean = false;
  public currentAppData: any = null;
  public currentRosterData: any = null;
  public notifications = [];
  public notificationsNewCount: number = 0;
  public notificationCheck: ExternalProcess = new ExternalProcess();
  public lastNotificationChecked: Date = null;
  public userNavigationLists: any = {'quoterequests':[],'quotes':[],'orders':[],'informationrequests':[]};
  public userNavigationListUrl = null;

  public currentVideo: any = null;
  public lastActivationKeyReceivedDate: any=null;
  public initialStartup: boolean=true;
  public initialStartupTime: any=null;
  private errorLogQueue = [];

  public currentUserData: UserData = new UserData();
  public currentUserDataChanged: boolean = false;
  public updateIsRunning: boolean = false;

  private _authorised: any=null;
  private _authenticated: boolean=false;
  private _gaTrackerStarted: boolean=false;
  private _appTappedId: string='';
  private _jwtsk: any = [];
  private _matomoInitialised: boolean=false;  
  private _matomoConfigured: boolean=false;

  public acceptedTC: boolean=false;
  public deviceToken: string='';

  public platformIsReady: boolean=false;
  public platformIsCordova: boolean=false;

  public initialiseSecureStorageError: boolean = false;
  public authorisationErrorMessage: string = "";
  private ss: any = null;
  private secureStorageInitialising: boolean = false;
  private storage: Storage;
  private lastAccessed: Date = new Date(2019,1,1);
  public lastUpdateChecked: Date = null;  
  public rosterAgendaStartDate: any = null;
  public allowRosterCalendarMultiselect: boolean = true;
  public calendarTmpIdVal: string = "111";
  public calendarSelectedDays: any = {};
  public rosterShiftTypes = [{name:'Rostered On',value:'RO',eventIconClass:'DefaultEvent'},
  {name:'Overtime',value:'OT',eventIconClass:'DefaultEvent'},
  {name:'Re Called',value:'RC',eventIconClass:'DefaultEvent'},
  {name:'Court',value:'CRT',eventIconClass:'DefaultEvent'},
  {name:'Training',value:'TR',eventIconClass:'DefaultEvent'},
  {name:'Other',value:'OTR',eventIconClass:'OtherEvent'},
  {name:'On Call',value:'OC',eventIconClass:'OncallEvent'},
  {name:'Sick/Carers Leave',value:'SL/CL',eventIconClass:'LeaveEvent'},
  {name:'Annual Leave',value:'AL',eventIconClass:'LeaveEvent'},
  {name:'Rostered Leave Day',value:'RLD',eventIconClass:'LeaveEvent'},
  {name:'Rest Day',value:'RD',eventIconClass:'LeaveEvent'}];

  constructor(
        private appVersion: AppVersion,
        private device: Device,
        private dialogs: Dialogs, 
        private domSanitizer: DomSanitizer,
        public events: EventsService,
        private file: File,
        private inAppBrowser: InAppBrowser,
        public http: HttpClient, 
        public matomoTracker: MatomoTracker,
        private matomoInjector: MatomoInjector,
        private network: Network,
        public platform: Platform,
        public router: Router,
        private strg: Storage,
        private alertController: AlertController,
        private webView: WebView        
    ) { 
    if (this.appType == 'public') {
      this._authorised = true;
      this._authenticated = true;
    }
    this.appsettings.appName  = 'Connect Auto Parts';
    this.storageInit();
    //document.addEventListener("deviceready", this.onDeviceReady, false);
    this.platform.ready().then(() => {
      this.platformIsReady = true;
      this.log(1,'Settings platform is "ready" 1 platforms=' + this.platform.platforms());

      this.platform.pause.subscribe(
        (result) => {
        // app is put in the background
        this.log(1,'SettingsProvider on pause success result=' + JSON.stringify(result));
        this.trackMatomoEvent("Interaction", "Pause", "App Pause", null);
        }, (error) =>  {
          this.log(2,'SettingsProvider on pause error=' + JSON.stringify(error));
      });    

      this.platform.resume.subscribe(
        (result) => {
        // app is put in the foreground
        this.log(1,'SettingsProvider on resume success result=' + JSON.stringify(result));
        this.trackMatomoEvent("Interaction", "Resume", "App Resume", null);

        this.log(1,'SettingsProvider on resume success appType=' + this.appType + ' _authorised=' + this._authorised + 
        ' lastActivationKeyReceivedDate=' + this.lastActivationKeyReceivedDate +
        ' userAuthenticated=' + this.userAuthenticated + ' url=' + this.router.url);
        if (this.appType == 'public') {
          // do we want to go to HomePage on resume or stay on whatever page? For now go to home screen because
          // check for update will happen there
          this.log(1,'SettingsProvider on resume: 1 current url=' + this.router.url);
          if (this.router.url != '/home') {
            this.router.navigateByUrl('/home');
          }          
        } if (!this._authorised) {
          if (this.platformIsCordova) {
            if (this.lastActivationKeyReceivedDate)
              this.log(1,'SettingsProvider on resume now=' + Date.now() + ' timeDiffInMinutes=' + this.timeDiffInMinutes(Date.now(),this.lastActivationKeyReceivedDate));
            if (this.lastActivationKeyReceivedDate && 
              this.timeDiffInMinutes(Date.now(),this.lastActivationKeyReceivedDate) < 61) {
              this.router.navigateByUrl('/authorise-key');
            } else {
              this.router.navigateByUrl('/authorise-email');
            }
          } else {
            if (this.router.url != '/login') {
              this.router.navigateByUrl('/login');
            }
          }
        } else if (this.currentAppData && !this.userAuthenticated) {
          this.log(1,'SettingsProvider on resume: 2 current url=' + this.router.url);
          if (this.router.url != '/login') {
            this.router.navigateByUrl('/login');
          }
        }
      }, (error) =>  {
        this.log(2,'SettingsProvider on resume error=' + JSON.stringify(error));
      });     

      // GR deviceToken should be define on registration ready event for push notification. 
      // Should we store it localy or always get from that event?
      // https://stackoverflow.com/questions/36717556/retrieve-device-token-from-device-using-ionic-framework
      // https://github.com/phonegap/phonegap-plugin-push/issues/1938
      this.deviceToken = 'nopushnotificationsconfigured';
      //this.platformIsCordova = this.platform.is('cordova')
      // GR. It is probably not good name but we use this setting to find out if we work from browser.
      this.platformIsCordova = (this.platform.is('ios') || this.platform.is('android'));
      this.setUserDeviceDetails();
      if (this.device && this.platform.is("desktop") ) {
        this.platformIsCordova = false;
      }

      if (this.device && this.platform.is("mobileweb")) {
        this.platformIsCordova = false;
      }
      if (this.platformIsCordova) {
        this.passwordAuthentication = false;
        //this.setUserDeviceDetails();
        if (this.useFirebase) {
          //this.setupFcmNotifications();
        }
      } else if (this.passwordAuthenticationInBrowserEnabled) {
        this.passwordAuthentication = true;
        this._authorised = true; // - do not require email authorisation
      }
      this.log(1,'Settings platform is "ready" 2 platforms=' + this.platform.platforms() + ' this.platformIsCordova=' + this.platformIsCordova + ' this.passwordAuthenticationInBrowserEnabled=' + this.passwordAuthenticationInBrowserEnabled + ' this.passwordAuthentication=' + this.passwordAuthentication);
      
    }); 
    this.serverApiUrl = this.sourceHost + '/App_Sites/' + this.appTextId + '/Javascript/Ajax/external.aspx';
    this.serverApiUrl2 = this.sourceHost + '/App_Sites/' + this.appTextId + '/Javascript/Ajax/external.ashx';
    this.serverApiUrl2A = this.sourceHost + '/App_Sites/' + this.appTextId + '/Javascript/Ajax/externalA.ashx';
    this.log(1,'SettingsProvider Provider constructor end');
  }

  async storageInit() {
    await this.strg.defineDriver(CordovaSQLiteDriver);
    const storage = await this.strg.create();
    this.storage = storage;
  }

  // execute predefined cms command on the server by sending POST request and receive JSON
  // structure like that: {"Success":false, "Message":"No account found", "Data":{}};
  // commandParametersQS is "QS" like string e.g. "email=test@gamil.com&phone=04229988"
  // Note that values in commandParametersQS should be encoded with encodeURIComponent()
  executeCommand(commandName: string, commandParametersQS?: string, reqestOptions?: object) {
    if (!reqestOptions) {
      reqestOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'
        })
      };
    }
    let body: any = "c=" + commandName + "&t=" + this.userToken + "&did=" + this.deviceId + "&ati=" + this.appTextId;
    if (commandParametersQS) {
      body += "&" + commandParametersQS;
    }
    this.log(1,'executeCommand before post serverApiUrl=' + this.serverApiUrl + " body=" + this.abbreviate(body,50));
    return this.http.post(this.serverApiUrl, body, reqestOptions);   
  }

  // execute predefined cms command on the server by sending POST request to serverApiUrl2 and receive JSON
  // structure like that: {"Success":false, "Message":"No account found", "Data":{}};
  // commandParametersQS is "QS" like string e.g. "email=test@gamil.com&phone=04229988"
  // Note that values in commandParametersQS should be encoded with encodeURIComponent()
  // Note that unlike executeCommand command is authenticated by sending sid (sessionid) It is supposed to be
  // valid current session. If not then function will try to "reauthenticate" user as a part of this call
  executeCustomCommand(commandName: string, commandParametersQS?: string, reqestOptions?: object, callcounter: number = 1) {
    let t = this;
    return new Promise(
      function (resolve, reject) {
        if (!reqestOptions) {
          reqestOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/x-www-form-urlencoded'
            })
          };
        }
        t.log(1,'executeCustomCommand callcounter=' + callcounter + " sid=" + t.currentUserData.SessionId);
        if (!t.currentUserData.SessionId) {
          if (!t.currentUserData.SessionId) {
            // reauthenticate and repeate the process
            t.reAuthenticateUser().then((au:boolean)=>{
              t.log(1,'reAuthenticateUser: au=' + au + ' 1 sid=' + t.currentUserData.SessionId);
              t._authenticated = au;
              t.executeCustomCommand(commandName,commandParametersQS, reqestOptions, 2).then((result)=>{
                resolve(result);
              }).catch(error=>{
                t.log(1,'executeCustomCommand error after reAuthenticateUser 1 error=' + JSON.stringify(error));
                reject(error);
              });
            }).catch(error=>{
              t.log(2,'executeCustomCommand error in reAuthenticateUser 1 error=' + JSON.stringify(error));
              reject(error);
            });            
          } else {
            // that's an error
            reject(new Error("Problem with user authentication"));
          }
        }
        let body: any = "c=" + commandName + "&sid=" + t.currentUserData.SessionId + "&userid=" + t.currentUserData.Userid + "&companyid=" + t.currentUserData.CompanyId;
        if (commandParametersQS) {
          body += "&" + commandParametersQS;
        }
        t.log(1,'executeCustomCommand before post serverApiUrl=' + t.serverApiUrl2 + " body=" + t.abbreviate(body,50));
        //return t.http.post(t.serverApiUrl2, body, reqestOptions);   
        t.http.post(t.serverApiUrl2, body, reqestOptions)
        .subscribe((result: any) => { 
            if (!result) {
              t.log(2,'executeCustomCommand - empty result from the server');
              reject('empty result from the server');            
            } else if (!result.Success && callcounter == 1 && result.Message == 'Unautheticated Call') {
              t.log(1,'executeCustomCommand Unautheticated Call response - try to reauthenticate');
              t.reAuthenticateUser().then((au:boolean)=>{
                t.log(1,'reAuthenticateUser:  au=' + au + ' 2 sid=' + t.currentUserData.SessionId);
                t._authenticated = au;
                t.executeCustomCommand(commandName,commandParametersQS, reqestOptions, 2).then((result)=>{
                  resolve(result);
                }).catch(error=>{
                  t.log(1,'executeCustomCommand error after reAuthenticateUser 2 error=' + JSON.stringify(error));
                  reject(error);
                });
              }).catch(error=>{
                t.log(2,'executeCustomCommand error in reAuthenticateUser 2 error=' + JSON.stringify(error));
                reject(error);
              });               
            } else {
              t.log(1,'executeCustomCommand result=' + t.abbreviate(JSON.stringify(result),200));
              resolve(result);
            }
          },  error =>  {
            t.log(2,'executeCustomCommand error=' + JSON.stringify(error));
            reject(error);
         });
    });
  }  


  // execute predefined cms command on the server by sending POST request to serverApiUrl2 and receive JSON
  // structure like that: {"Success":false, "Message":"No account found", "Data":{}};
  // commandParametersQS is "QS" like string e.g. "email=test@gamil.com&phone=04229988"
  // Note that values in commandParametersQS should be encoded with encodeURIComponent()
  // Note that unlike executeCommand command is authenticated by sending sid (sessionid) It is supposed to be
  // valid current session. If not then function will try to "reauthenticate" user as a part of this call
  submitFormData(commandName: string, formData: FormData, callcounter: number = 1) {
    let t = this;
    return new Promise(
      function (resolve, reject) {
        //let reqestOptions = {
        //  headers: new HttpHeaders({
        //    'Content-Type':  'multipart/form-data'
        //  })
        //};
        t.log(1,'submitFormData callcounter=' + callcounter + " sid=" + t.currentUserData.SessionId);
        if (!t.currentUserData.SessionId) {
          if (!t.currentUserData.SessionId) {
            // reauthenticate and repeate the process
            t.reAuthenticateUser().then((au:boolean)=>{
              t.log(1,'reAuthenticateUser: au=' + au + ' 1 sid=' + t.currentUserData.SessionId);
              t._authenticated = au;
              t.submitFormData(commandName,formData, 2).then((result)=>{
                resolve(result);
              }).catch(error=>{
                t.log(1,'submitFormData error after reAuthenticateUser 1 error=' + JSON.stringify(error));
                reject(error);
              });
            }).catch(error=>{
              t.log(2,'submitFormData error in reAuthenticateUser 1 error=' + JSON.stringify(error));
              reject(error);
            });            
          } else {
            // that's an error
            reject(new Error("Problem with user authentication"));
          }
        }
        //let body: any = "c=" + commandName + "&sid=" + t.currentUserData.SessionId + "&uid=" + t.currentUserData.Userid + "&cid=" + t.currentUserData.CompanyId;
        formData.append('c', commandName);
        formData.append('sid', t.currentUserData.SessionId);
        formData.append('userid', t.currentUserData.Userid);
        formData.append('companyid', t.currentUserData.CompanyId);

        t.log(1,'submitFormData before post serverApiUrl=' + t.serverApiUrl2 + " c=" + commandName);
        //return t.http.post(t.serverApiUrl2, body, reqestOptions);   
        t.http.post(t.serverApiUrl2, formData)
        .subscribe((result: any) => { 
            if (!result.Success && callcounter == 1 && result.Message == 'Unautheticated Call') {
              t.log(1,'submitFormData Unautheticated Call response - try to reauthenticate');
              t.reAuthenticateUser().then((au:boolean)=>{
                t.log(1,'reAuthenticateUser:  au=' + au + ' 2 sid=' + t.currentUserData.SessionId);
                t._authenticated = au;
                t.submitFormData(commandName,formData, 2).then((result)=>{
                  resolve(result);
                }).catch(error=>{
                  t.log(1,'submitFormData error after reAuthenticateUser 2 error=' + JSON.stringify(error));
                  reject(error);
                });
              }).catch(error=>{
                t.log(2,'submitFormData error in reAuthenticateUser 2 error=' + JSON.stringify(error));
                reject(error);
              });               
            } else {
              t.log(1,'submitFormData result=' + t.abbreviate(JSON.stringify(result),200));
              resolve(result);
            }
          },  error =>  {
            t.log(2,'submitFormData error=' + JSON.stringify(error));
            reject(error);
         });
    });
  }  

  getBinaryFile(fileUrl) {
    return this.http.get(fileUrl, {responseType: 'blob'});
  }

	configureMatomoAndFirebase() {
		if (this.isConnected() && this.trackMatomoAnalytics) {
      this.log(1,'configureMatomoAndFirebase _matomoInitialised=' + this._matomoInitialised + ' matomoUrl=' + this.matomoUrl + ' matomoSiteId=' + this.matomoSiteId);
			if (!this._matomoInitialised) {
				this.matomoInjector.init(this.matomoUrl, 0);
				this.matomoTracker.setTrackerUrl(this.matomoUrl + 'piwik.php');     
				this.matomoTracker.setSiteId(this.matomoSiteId); 
				this._matomoInitialised = true;
			}
			if (!this._matomoConfigured) {
				this.matomoTracker.enableCrossDomainLinking();
				this.matomoTracker.setCookieDomain(this.getHostname(this.sourceHost));  // do we consider web site as "local" ? Probably not.
				this.matomoTracker.setDomains([this.appTextId + ".app",this.getHostname(this.sourceHost)]);  // do we consider web site as "local" ? Probably not.
				this.matomoTracker.enableLinkTracking(true); // it tracks "standard" clicks by default  
				this._matomoConfigured = true;
			}
    } 
    if (this.useFirebase) { 
    //  this.firebase.setAnalyticsCollectionEnabled(this.trackFirebaseAnalytics);
    }
    this.configureMatomoUser();
	}

  configureMatomoUser() {
    let t = this;
    if (t.isConnected() && t.trackMatomoAnalytics) {  
      // GR we use deviceId as matomo UserId. *** That changed - see below
      //t.matomoTracker.setUserId(t.deviceId);
      t.matomoTracker.setCustomVariable(1,"deviceid",t.deviceId,"visit");
      if (t.userAuthenticated) {
        // GR. 2021-09-03 set up useid the same way as on a site
        t.matomoTracker.setUserId(t.currentUserData.Username + '_' + t.currentUserData.Userid);
        //t.matomoTracker.setCustomVariable(2,"userid",t.currentUserData.Userid,"visit"); 
      }
      if (t.currentUserData && t.currentUserData.DeviceModel) {
        t.matomoTracker.setCustomVariable(3,"devicemodel",t.currentUserData.DeviceModel,"visit"); 
      }       
      let source = "web";
      if (t.platformIsCordova) {
        source = "app";
      }
      t.matomoTracker.setCustomVariable(4,"source",source,"visit");                    
      t.matomoTracker.setCustomVariable(5,"role",t.currentUserRole,"page");  // role can change during a visit                  
      //t.matomoTracker.disableCookies();
      this.log(1,'configureMatomoUser t.userAuthenticated=' + t.userAuthenticated + ' source=' + source);
    }     
  }

  // get new or stored deviceId and return promise - used for the public app
  getDeviceId() {
		var t = this;	
		return new Promise(
		  function (resolve, reject) {    
        t.platform.ready().then(() => {
          t.getKey('deviceid').then((deviceid:string)=>{
            t.log(1,"deviceid from storage deviceid=" + deviceid);
            t.deviceId = deviceid;
            if (!deviceid) {
              t.deviceId = t.generateUUID(); 
              t.log(1,"Create new deviceId=" + t.deviceId);  
              t.setKey('deviceid',t.deviceId);  
            }
            resolve(t.deviceId);
          }).catch((error)=>{
            t.deviceId = t.generateUUID();
            t.log(1,"Can not read deviceid from storage " + JSON.stringify(error) + " created deviceId=" + t.deviceId);  
            t.setKey('deviceid',t.deviceId);
            resolve(t.deviceId);
          });
			  });
      }
    )
  }

  // setting up device info. It must be done after device ready. We do it on successful login.
  setUserDeviceDetails() {
    this.log(1,"setUserDeviceDetails this.device=" + this.device + " cordova=" + this.platform.is('cordova'));
    if (this.device) {
      this.log(1,"setUserDeviceDetails this.device.model=" + this.device.model);
    }
    // GR those values below are supposed to be taken from device object after
    // ionic cordova plugin add cordova-plugin-device &
    // import { Device } from '@ionic-native/device/ngx' constructor(private device: Device);
    if (this.device && this.platform.is('cordova')) {
      this.currentUserData.DeviceManufacturer = this.device.manufacturer;
      this.currentUserData.DeviceModel = this.device.model;
      this.currentUserData.DevicePlatform = this.device.platform;
      this.currentUserData.DeviceVersion = this.device.version;
      this.log(1,"setUserDeviceDetails this.device.platform=" + this.device.platform);
    }
  }

  // this should be called when Authorisation process starts first or next time
  // so one will HAVE TO enter key sent in email to become authorised again
  authorisationStarted() {
    this._authorised = false;
  }

  // creates a html string with options for dropdowns
  GetOptionsFromPipeSeparatedString(capPartCategories) { 
    let options = '';
    if (capPartCategories) {
      let parts = capPartCategories.split('|');
      for (var i=0;i<parts.length; i++) {
        let p = parts[i].trim();
        options += '<option value="'+p.replace(/"/g, '\\"')+'">'+p+'</option>\n';
      }      
    }
    this.log(1,'GetOptionsFromPipeSeparatedString: capPartCategories=' + capPartCategories);
    return options;
  }

  // creates dropdown array
  GetDropdownFromPipeSeparatedString(capPartCategories,currentValue='') { 
    let arr = [];
    if (capPartCategories) {
      let parts = capPartCategories.split('|');
      for (var i=0;i<parts.length; i++) {
        let category = {value:parts[i].trim(),selected:(currentValue.indexOf(parts[i].trim()) == 0) ? 'selected' : ''};
        arr.push(category);
      }      
    }
    return arr;
  }  
  
  // creates checkboxes array
  GetCheckboxesFromPipeSeparatedString(capPartCategories,currentValue='') { 
    let checkboxes = [];
    if (capPartCategories) {
      let parts = capPartCategories.split('|');
      for (var i=0;i<parts.length; i++) {
        let category = {value:parts[i].trim(),isItemChecked:(currentValue.indexOf(parts[i].trim()) > -1)};
        checkboxes.push(category);
      }      
    }
    this.log(1,'GetCheckboxesFromPipeSeparatedString: capPartCategories=' + capPartCategories + ' length=' + checkboxes.length);
    return checkboxes;
  }
  
  handleClick(event) {
    let s = this;
    let t = event.target;
    if (t.tagName != "A") {
      t = event.target.parentNode;
      if (t.tagName != "A") {
        t = event.target.parentNode;
      }
    }
    s.log(1,'*** handleClick t.tagName=' + t.tagName + ' href=' + t.href);
    if (t.tagName == "A") { 
      //let href = event.target.href.replace(/https?\:\/\/localhost\:\d+\//,'');
      let href = t.href;
      let currentHost = this.getHostname(window.location.href);
      if (href.indexOf('file:') > -1 || (currentHost && href.indexOf(currentHost) > -1)) {
        let parts = href.split('/');
        href = parts[parts.length-1];
      }
      s.log(1,'*** handleClick href=' + href + ' s.sourceHost=' + s.sourceHost + ' s.appSiteHost=' + s.appSiteHost + ' currentHost=' + currentHost);
      if (href.indexOf('/') == 0) {    
        // that's relative link which now will be transformed to the main site!
        href = s.appSiteHost + href;
      }
      if (href.indexOf('http') == 0) {  
        // GR Do we want to manually  call matomo functions here?  
        let extRegex = /.*\.(7z|aac|arc|arj|apk|asf|asx|avi|bin|bz|bz2|csv|deb|dmg|doc|exe|flv|gif|gz|gzip|hqx|jar|jpg|jpeg|js|mp2|mp3|mp4|mpg|mpeg|mov|movie|msi|msp|odb|odf|odg|odp|ods|odt|ogg|ogv|pdf|phps|png|ppt|qt|qtm|ra|ram|rar|rpm|sea|sit|tar|tbz|tbz2|tgz|torrent|txt|wav|wma|wmv|wpd|xls|xml|z|zip)/;
        if (href.match(extRegex)) {
          s.trackMatomoDownload(href);
        } else {
          s.trackMatomoLink(href);
        } 
      }
      if ((href.indexOf(s.sourceHost) == 0 || href.indexOf(s.appSiteHost) == 0) && href.indexOf('autologin=1') > -1) {
        s.getJwtToken(href).then(token=>{
          let url = href.replace('autologin=1','jwt=' + token)
          s.log(1,'clicked link: ' + href + ' url=' + url);
          this.openExternalUrl(url);
          //window.open(url, '_system', 'location=yes');
          return false;
        }).catch(error=>{
          s.log(2,'error getting Jwt token: ' + error);
          //window.open(href, '_system', 'location=yes');
          this.openExternalUrl(href);
          return false;                        
        })        
      } else if (href.indexOf('#') == 0) {
        let el = document.getElementById(href.substring(1));
        s.log(1,'*** handleClick local anchor link id=' + href.substring(1) + ' el=' + el);
        if (el) {
          el.scrollIntoView();      
        }
      } else if (href.indexOf('http') == -1 && href.indexOf('Page') > -1) {
        // we assume that this is a reference to local page which may have form NameOfStaticPage or DynamicPage.id
        let parts = href.split('.');
        if (parts.length == 1) {
          s.openPageByStaticUrlAndId(parts[0],'');
        } else {
          s.openPageByStaticUrlAndId('',parts[1]);
        }
      } else {
        //this.iab.create(href, "_system");
        //window.open(href,'_system', 'location=yes');
        this.openExternalUrl(href);
      }
      return false;
    }
  }

  logout() {
    this.userAuthenticated = false;
    if (!this.platformIsCordova) {
      this.setCookie('iobsid', '', this.passwordSessionTimeInMinutes);
      this.setCookie('iobrole', '', this.passwordSessionTimeInMinutes);
    }    
    this.openPage('/login');
  }

  openExternalUrl(url) {
    this.log(1,'openExternalUrl: url=' + url);
    if ((url.indexOf(this.sourceHost) > -1 || url.indexOf(this.appSiteHost) > -1) && url.indexOf('autologin=1') > -1) {
      this.getJwtToken(url).then(token=>{
        url = url.replace('autologin=1','jwt=' + token);
        this.log(1,'openExternalUrl: new url=' + url);
        this.openExternalUrl(url);
      }).catch(error=>{
        this.log(2,'openExternalUrl: error getting Jwt token: ' + error);
        url = url.replace('autologin=1','');
        this.openExternalUrl(url);                     
      })        
    } else {
      this.log(1,'openExternalUrl: this.platformIsCordova=' + this.platformIsCordova);
      if (!this.platformIsCordova) {
        // click from website. We want it to behave as "normal click on a link" does
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: url,
        }).click();        
        //window.open(url,'_blank');
      } else if (this.platform.is('ios')) {
        this.log(1,'openExternalUrl: ios open url in inAppBrowser');
        this.inAppBrowser.create(url, '_system');
      } else {
        this.log(1,'openExternalUrl: cordova open url with window.open');
        window.open(url,'_system', 'location=yes');
      }       
    }
    return false;
  }

  openPageByStaticUrlAndId(staticpageurl, pageid = '') {
    this.log(1,'openPageByStaticUrlAndId: staticpageurl=' + staticpageurl + ' pageid=' + pageid);
    let pageUrl = "DynamicPage";
    let params = {};
    if (staticpageurl) {
      pageUrl = staticpageurl;
    } else {
      params = {id: pageid};
    }
    this.openPage(pageUrl, params);
  }

  getDynamicIdForStaticPage(staticpageurl) {
    let pageid = '';
    for (let id in this.staticPageMap) { 
      if (this.staticPageMap[id] == staticpageurl) {
        pageid = id;
      }
    }
    return pageid;
  }

  openPage(pageName, pageParams: any = {}) {
    this.log(1,'settings openPage this.userAuthenticated=' + this.userAuthenticated);
    this.log(1,'settings openPage pageName=' + pageName + ' pageParams=' + JSON.stringify(pageParams) 
    + ' current url=' + this.router.url + ' href=' + window.location.href);
    if (pageName.indexOf('http') == 0) {
      this.openExternalUrl(pageName);
    } else if (this.router) {
      this.previousUrl = this.router.url;
      if (pageParams && pageParams.id && this.staticPageMap[pageParams.id]) {
        pageName = this.staticPageMap[pageParams.id];
        if (pageName.substring(0,1) != "/") {
          pageName = "/" + pageName;
        }
        // Should we also convert URLs like AuthoriseEmailPage to authorise-email automatically?  Do it below
        if (pageName.endsWith('Page')) { 
          pageName = pageName.substring(0,pageName.length-4).replace(/([A-Z])/g,"\-$1").toLowerCase().replace('/-','/');
        }            
        this.log(1,'settings openPage staticPageMap[pageParams.id]=' + this.staticPageMap[pageParams.id] + ' url=' + pageName);
        // GR 20210219 - shouldn't we push id of "original" page to mapped static page as well (see below)? That will allow
        // to display "other data" like body passed dynamically. We are doing it more hard coded way on a home page already.
        // this.navCtrl.push(this.staticPageMap[pageParams.id], pageParams);
      } else {
        if (pageName.substring(0,1) != "/") {
          pageName = "/" + pageName;
        }
        // Should we also convert URLs like AuthoriseEmailPage to authorise-email automatically?  Do it below
        if (pageName.endsWith('Page')) { 
          pageName = pageName.substring(0,pageName.length-4).replace(/([A-Z])/g,"\-$1").toLowerCase().replace('/-','/');
        }              
        if (pageParams && pageParams.id) {
          pageName = pageName + "/" + pageParams.id;
        } 
        this.log(1,'settings openPage url=' + pageName);
      }
      if (this.appType == 'public' || this.userAuthenticated || pageName.toLowerCase().indexOf('authorise') >= 0 || pageName.toLowerCase().indexOf('terms') >= 0) {
        this.lastClickedUrl = "";        
        if (pageName.startsWith('/quote-request-add') && (pageName.indexOf('extraid') > -1 || (pageParams && pageParams.extraid))) {
          if (this.router.url.startsWith('/quote-requests')) {
            this.trackMatomoEvent("Marketplace Trading", "Relist Quote Request", "Relist button on Quotes Recived screen", null);
          } else if (this.router.url.startsWith('/orders/current')) {
            this.trackMatomoEvent("Marketplace Trading", "Relist Quote Request", "Relist button on Current Orders screen", null);
          } else if (this.router.url.startsWith('/orders/archived')) {
            this.trackMatomoEvent("Marketplace Trading", "Relist Quote Request", "Relist button on Past Orders screen", null);
          }
        }
        if (pageParams && (pageParams.extraid || pageParams.role)) {
          if (pageParams.id) {
            delete pageParams.id;
          }
          this.router.navigate([pageName, pageParams])
        } else {
          if (this.router.url != '/login' && this.router.url != '/splash') {
            this.lastClickedUrl = pageName;
            this.lastClickedUserId = this.currentUserData.Userid;
            if (this.currentUserRole) {
              this.lastClickedUserRole = this.currentUserRole;
            }
            if (this.currentUserRole == 'buyer' && this.switchRolesEnabled) {
              this.lastClickedUrl = pageName + ';role=buyer'; 
            }
          }
          this.log(1,'settings openPage 1 lastClickedUrl=' + this.lastClickedUrl + ' this.lastClickedUserId=' + this.lastClickedUserId + ' this.lastClickedUserRole=' + this.lastClickedUserRole);
          this.router.navigateByUrl(pageName);
        }
      } else {
        //if (pageName != '/login' && !pageParams.extraid) {
        //  this.lastClickedUrl = pageName;
        //} else 
        if (this.router.url != '/login' && this.router.url != '/splash') {
          if (this.router.url == '/' && window.location.href && window.location.href.indexOf('#') > -1) {
            this.lastClickedUrl = window.location.href.split('#')[1];
          } else {
            this.lastClickedUrl = this.router.url;
          }
          //this.lastClickedUserId = this.currentUserData.Userid;
          //this.lastClickedUserRole = this.currentUserRole; 
        }
        this.log(1,'settings openPage 2 lastClickedUrl=' + this.lastClickedUrl + ' this.lastClickedUserId=' + this.lastClickedUserId + ' this.lastClickedUserRole=' + this.lastClickedUserRole);
        this.router.navigateByUrl("/login");
      } 
    }
  } 

  
  openLinkFromModalPage(pageurl) {
    let s = this;    
    if (pageurl.indexOf("http") == 0) {
      // that is external link
      if ((pageurl.indexOf(s.sourceHost) == 0 || pageurl.indexOf(s.appSiteHost) == 0) && pageurl.indexOf('autologin=1') > -1) {
        s.getJwtToken(pageurl).then(token=>{
          let url = pageurl.replace('autologin=1','jwt=' + token)
          s.log(1,'clicked link: url=' + url);
          window.open(url, '_system');
        }).catch(error=>{
          s.log(2,'error getting Jwt token: ' + error);
          window.open(pageurl, '_system');                    
        });      
      } else {
        window.open(pageurl, '_system'); 
      }
    } else if (pageurl.indexOf("Page") == pageurl.length - 4) {
      // that's a bit brutal but we consider that page a static page
      s.openPage(pageurl, {});
    } else {
      // must be dynamic page
      s.openPage("DynamicPage", {id: pageurl});
    }
  }

  sanitize(html) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  sanitizeUrl(url) {
    //let newUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
    this.log(0,"sanitizeUrl: url=" + url);
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  GetQuoteRequestImages(images,CompanyID,QuoteRequestID) {
    ///images/marketplace/companies/cid/quoterequest_id/resized/unique_name.jpg
    var arrImages = [];
    this.log(1,'GetQuoteRequestImages: images=' + images + ' CompanyID=' + CompanyID + ' QuoteRequestID=' + QuoteRequestID);
    if (images) {
      // create list of image tags to display images from the server
      let parts = images.split(',');
      let url0 = this.sourceHost + '/images/marketplace/companies/' + CompanyID + '/quoterequest_' + QuoteRequestID + '/resized/';
      for (let i=0;i<parts.length;i++) {
        let url = url0 + parts[i].trim();
        arrImages.push(url);
        this.log(1,'GetQuoteRequestImages: i=' + i + ' url=' + url);
      }
    }
    return arrImages;
  }  

  acceptQuote(id, p: any = null) {
    let s = this;
    s.log(1,'acceptBid');
    const alert = this.alertController.create({
      //header: 'Request Info',
      message: `<h3>Just before you accept this quote...</h3> 
      <div>Please check the quote details accurately reflects what you like to order.
      </div>
      <div>
      If you need to request changes, please go to REQUEST UPDATE before accepting the quote.
      </div>`
      ,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Accept Quote',
        handler: () => {
          let screen = "Quote";
          if (s.router.url.indexOf('quote-requests') > 0) {
            screen = 'Quote Received';
          }
          s.trackMatomoEvent("Marketplace Trading", "Accept Quote", "Accept button on " + screen + " screen", null);
          s.executeCustomCommand('AcceptQuote','quoteid=' + id ).then((result: any)=>{
            s.log(1,'acceptQuote: result.Success=' + result?.Success + ' getUserNavigationListUrl=' + s.getUserNavigationListUrl());
            if (result && result.Success) {
              // redirect to home page or request list view
              let rurl = s.getUserNavigationListUrl();
              if (this.router.url != rurl) {
                s.openPage(s.getUserNavigationListUrl());
              } else if (p) {
                p.ionViewWillEnter();
              }
            } else {
              s.displayServerError(result.Message);
            }
          }).catch(error=>{
            s.displayServerError("Error happened during Quote Acceptance operation. Please try again later.");
            s.log(2,'*** error returned from acceptQuote" call error: ' + JSON.stringify(error));
          });           
        }
      }, 
        {
          text: 'Cancel'
        }
      ]
    }).then(alert=> {
      alert.present();
    });     
  }

  rejectQuote(id, p: any = null) {
    let s = this;
    s.log(1,'rejectQuote');
    const alert = this.alertController.create({
      //header: 'Request Info',
      message: `<h3>Confirm that you would like to reject this quote</h3> 
      <div>What a shame!
      </div>
      <div>
      Please confirm that you would like to reject this Quote?
      </div>
      <div>
      An email will be sent to the Seller letting them know.
      </div>`
      ,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Reject Quote',
        handler: () => {
          let screen = "Quote";
          if (s.router.url.indexOf('quote-requests') > 0) {
            screen = 'Quote Received';
          }
          s.trackMatomoEvent("Marketplace Trading", "Reject Quote", "Reject button on " + screen + " screen", null);          
          s.executeCustomCommand('RejectQuote','quoteid=' + id ).then((result: any)=>{
            s.log(1,'rejectQuote: result.Success=' + result?.Success + ' getUserNavigationListUrl=' + s.getUserNavigationListUrl());
            if (result && result.Success) {
              // redirect to home page or request list view
              let rurl = s.getUserNavigationListUrl();
              if (this.router.url != rurl) {
                s.openPage(s.getUserNavigationListUrl());
              } else if (p) {
                p.ionViewWillEnter();
              }
            } else {
              s.displayServerError(result.Message);
            }
          }).catch(error=>{
            s.displayServerError("Error happened during Bid Rejection operation. Please try again later.");
            s.log(2,'*** error returned from rejectQuote" call error: ' + JSON.stringify(error));
          });           
        }
      }, 
        {
          text: 'Cancel'
        }
      ]
    }).then(alert=> {
      alert.present();
    });     
  }

  rejectRemainingQuotes(quoteRequest, p: any = null) {
    let s = this;
    s.log(1,'rejectRemainingQuotes: quoteRequest.ID=' + quoteRequest.ID);
    const alert = this.alertController.create({
      //header: 'Request Info',
      message: `<h3>Finish Auction</h3> 
      <div>
      That action will reject all 'not accepted' Bids for the Quote Request
      </div>`
      ,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Confirm',
        handler: () => {
          let screen = 'Quote Request';
          if (s.router.url.indexOf('/quote-requests/') > -1) {
            screen = "Quotes Received";
          }
          s.trackMatomoEvent("Marketplace Trading", "Complete Quote Request", "Complete button on " + screen + " screen", null);
          
          s.executeCustomCommand('rejectremainingquotes','quoterequestid=' + quoteRequest.ID ).then((result: any)=>{
            s.log(1,'rejectremainingquotes: result.Success=' + result?.Success + ' getUserNavigationListUrl=' + s.getUserNavigationListUrl());
            if (result && result.Success) {
              if (p) {
                //execute 'refresh' function if page object was provided
                p.ionViewWillEnter();
              } else {
                s.openPage(s.getUserNavigationListUrl());
              }
            } else {
              s.displayServerError(result.Message);
            }
          }).catch(error=>{
            s.displayServerError("Error happened during Reject Remaining Quote operation. Please try again later.");
            s.log(2,'*** error returned from rejectQuote" call error: ' + JSON.stringify(error));
          });           
        }
      }, 
        {
          text: 'Cancel'
        }
      ]
    }).then(alert=> {
      alert.present();
    });
  }

  addOrderNote(id,  p: any = null) {
    let s = this;
    s.log(1,'addOrderNote id=' + id);
    const alert = s.alertController.create({
      header: 'Order Note',
      message: `<textarea id="infomessage" name="infomessage" rows="6" cols="20" placeholder="Enter any notes here..."></textarea>
              <div id="AlertErrorBox" class="messageError"></div></div>`,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Add Note',
        handler: () => {
          let ele: any =  document.getElementById('AlertErrorBox');
          let elm: any =  document.getElementById('infomessage');
          var body = 'id=' + id + '&role=' + s.currentUserRole + '&message=' + encodeURIComponent(elm.value);
          s.trackMatomoEvent("Marketplace Trading", "Add Note to Order", "Add Note button on Order screen", null);          
          s.executeCustomCommand('addordernote',body).then((result)=>{
            s.log(1,'*** "addOrderNote" returns: ' + JSON.stringify(result));
            if (p) {
              //execute 'refresh' function if page object was provided
              p.ionViewWillEnter();
            } 
          }).catch(error=>{
            s.log(2,'*** error returned from addOrderNote" call error: ' + JSON.stringify(error));
            if (ele) {
              ele.innerHTML = "Error happened during adding note. Please try again later.";
              return false;            
            }
          }); 
        }
      },
      {
        text: 'Cancel'
      }]
    }).then(alert=> {
      alert.present();
    });     
  }

  changeOrderStatus(id,  status='', p: any = null) {
    let s = this;
    let statuses = s.coremodulesdata.autopartsmarketplace['OrderStatuses' + s.capitalize(s.currentUserRole)];
    let m = `<h3>Please select the updated status from the list bellow</h3> 
    <div> 
      <select id="infotype" name="infotype" class="fullWidth" placeholder="Please Select">` +
      s.GetOptionsFromPipeSeparatedString(statuses) + `
      </select>`;
    if (status) {
      m = 'Please confirm that order is ' + status;
    }
    s.log(1,'changeOrderStatus id=' + id);
    const alert = s.alertController.create({
      header: 'Change Order Status',
      message: m + `<textarea id="infomessage" name="infomessage" rows="6" cols="20" placeholder="Enter any notes here..."></textarea>
              <div id="AlertErrorBox" class="messageError"></div></div>`,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Change Status',
        handler: () => {
          let eli: any =  document.getElementById('infotype');
          let ele: any =  document.getElementById('AlertErrorBox');
          let elm: any =  document.getElementById('infomessage');
          if (ele && !status) {
            s.log(1,'infotype=' + eli.options[eli.selectedIndex].value);
            status = eli.options[eli.selectedIndex].value;
          }
          let screen = 'Order';
          if (s.router.url.indexOf('/orders/') > -1) {
            screen = "Current Orders";
          }
          s.trackMatomoEvent("Marketplace Trading", "Change Order Status", "Change Status button on " + screen + " screen", null);

          var body = 'id=' + id + '&status=' + encodeURIComponent(status) + '&role=' + s.currentUserRole + '&message=' + encodeURIComponent(elm.value);
          s.executeCustomCommand('updateorderstatus',body).then((result)=>{
            s.log(1,'*** "changeOrderStatus" returns: ' + JSON.stringify(result));
            if (p) {
              //execute 'refresh' function if page object was provided
              p.ionViewWillEnter();
            } 
          }).catch(error=>{
            s.log(2,'*** error returned from changeOrderStatus" call error: ' + JSON.stringify(error));
            if (ele) {
              ele.innerHTML = "Error happened during changing status. Please try again later.";
              return false;            
            }
          }); 
        }
      },
      {
        text: 'Cancel'
      }]
    }).then(alert=> {
      alert.present();
    });     
  }

  cancelObject(id, objecttype, p: any = null) {
    let s = this;
    s.log(1,'cancelObject ' + objecttype + ' id=' + id);
    let ctext = 'Order and may negatively impact your rating.';
    if (objecttype.toLowerCase() == 'quoterequest') {
      ctext = 'Quote Request and all related Quotes/Orders (if they exsist). It may negatively impact your rating.';
    } else if (objecttype.toLowerCase() == 'quote') {
      ctext = 'Bid and Order (if Bid was accepted). It may negatively impact your rating.';
    }
    let categories = s.coremodulesdata.autopartsmarketplace['CancelCategories' + s.getDefaultObjectName(objecttype) + s.capitalize(s.currentUserRole)];
    const alert = this.alertController.create({
      message: '<h3>Why are you choosing to cancel?</h3>' + 
      '<div>' + 
        '<select id="infotype" name="infotype" class="fullWidth" placeholder="Please Select">' +
        s.GetOptionsFromPipeSeparatedString(categories) +
        `</select>
        NOTE: This will cancel ` + ctext + `
        <div id="AlertErrorBox" class="messageError"></div></div>
      </div>`
      ,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Submit',
        handler: () => {
          let eli: any =  document.getElementById('infotype');
          let ele: any =  document.getElementById('AlertErrorBox');
          s.log(1,'infotype=' + eli.value);
          let screen = "Order";
          if (s.router.url.indexOf('/quotes/') > -1) {
            screen = "Active Bids";
          } else if (s.router.url.indexOf('/quote-add/') > -1) {
            screen = "Bid";
          } else if (s.router.url.indexOf('/orders/') > -1) {
            screen = "Current Orders";
          } else if (s.router.url.indexOf('/quote-requests/') > -1) {
            screen = "for Quote Request on Received Quotes";
          }
          s.trackMatomoEvent("Marketplace Trading", "Cancel", "Cancel button on " + screen + " screen", null);
          var body = objecttype.toLowerCase() + 'id=' + id + '&reason=' + encodeURIComponent(eli.value);
          s.executeCustomCommand('cancel' + objecttype.toLowerCase(),body).then((result)=>{
            s.log(1,'*** "cancelObject" returns: ' + JSON.stringify(result) + ' url=' + s.getUserNavigationListUrl() + ' p=' + p);
            if (p) {
              //execute 'refresh' function if page object was provided
              p.ionViewWillEnter();
            } else {
              // redirect to home page or corresponding list view
              s.openPage(s.getUserNavigationListUrl());              
            }
          }).catch(error=>{
            s.log(2,'*** error returned from cancelObject" call error: ' + JSON.stringify(error));
            if (ele) {
              ele.innerHTML = "Error happened during cancellation submission. Please try again later.";
              return false;            
            }
          }); 
        }
      },
      {
        text: 'Cancel'
      }
    ]
    }).then(alert=> {
      alert.present();
    });       
  }

  getDefaultObjectName(objecttype) {
    let name = '';
    if (objecttype) {
      name = objecttype.toLowerCase().replace(/s*$/,'');
      if (name == 'quoterequest') {
        name = 'QuoteRequest';
      } else if (name == 'quote') {
        name = 'Quote';
      } else if (name == 'order') {
        name = 'Order';
      }
    }
    return name;
  }

    // objecttype is one of: quoterequest, quote, order
    requestToDeleteData(username,containerElementId) {
      let s = this;
      let header = 'My Details';
      const alert = this.alertController.create({
        message: '<h3>' + header + `</h3>
        <div>Please confirm that you would like to request your data to be deleted?</div><br>
        <div>If you request that your data to be deleted, we will be in contact to arrange this for you.</div>
        <div>Note that you will no longer be able to access the app after your data is deleted.</div>
        `,
        animated: true,
        cssClass: 'lightBox',
        buttons: [{
          text: 'Confirm Request',
          handler: () => {
            let elm: any =  document.getElementById(containerElementId);
            if (elm) {
              var commandParameters = 'info=' + s.getUserAndDeviceInfoJson();
              s.log(1,'requestToDeleteData: before call to requesttodeletedata');
              s.executeCommand('requesttodeletedata',commandParameters).subscribe((result: any) => { 
                if (result.Success) {
                  elm.className = "informationMessage";
                  s.log(1,'*** "requestToDeleteData" returns: ' + JSON.stringify(result));
                  elm.innerHTML = "Your request to delete data has been submitted.";
                  s.trackMatomoEvent("Interaction", "Run", "Request To Delete Data", "1");
                } else {
                  // display error message
                  elm.className = "error";
                  elm.innerHTML = "Error happened during request to delete data submission. Please try again later "; 
                  s.log(2,'requestToDeleteData error message=' + result.Message);
                  s.trackMatomoEvent("Interaction", "Run", "Request To Delete Data", "0");
                }              
              },  error =>  {
                elm.className = "error";
                s.log(2,'*** error returned from requestToDeleteData" call error: ' + JSON.stringify(error));
                elm.innerHTML = "Error happened during request to delete data submission. Please try again later. ";       
                s.trackMatomoEvent("Interaction", "Run", "Request To Delete Data", "0");   
              });  
            } else {
              s.log(2,'*** requestToDeleteData: no containerElement found');
              return false;            
            }
          }
        },
        {
          text: 'Cancel'
        }
      ]
      }).then(alert=> {
        alert.present();
      });     
    }
  

  // objecttype is one of: quoterequest, quote, order
  requestInfo(id, objecttype, recipientCompanyId, requestForUpdate=false, p: any = null) {
    let s = this;
    let header = 'What is your question';
    if (requestForUpdate) {
      header = 'What is your request to change this quote';
    }
    let categoryName = 'QuestionCategories' + s.getDefaultObjectName(objecttype) + s.capitalize(s.currentUserRole);
    let categories = s.coremodulesdata.autopartsmarketplace[categoryName];
    s.log(1,'requestInfo categoryName=' + categoryName);
    const alert = this.alertController.create({
      //header: 'Request Info',
      message: '<h3>' + header + '</h3>' + 
      '<div>' + 
        '<select id="infotype" name="infotype" class="fullWidth" placeholder="Please Select">' +
        s.GetOptionsFromPipeSeparatedString(categories) +
        '</select>' +
        '<textarea id="infomessage" name="infomessage" rows="6" cols="20" placeholder="Enter any notes here..."></textarea>'+
      '<div id="AlertErrorBox" class="messageError"></div></div>'
      ,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Submit',
        handler: () => {
          let eli: any =  document.getElementById('infotype');
          let elm: any =  document.getElementById('infomessage');
          let ele: any =  document.getElementById('AlertErrorBox');
          s.log(1,'infotype=' + eli.options[eli.selectedIndex].value + ' infomessage=' + elm.value);
          if (elm.value) {
            let forobj = '';
            let screen = 'Order';
            if (s.router.url.indexOf('quote-request') > -1) {
              screen = 'Quote Received';
            } else if (s.router.url.indexOf('quote/') > -1) {
              screen = 'Quote';
            } else if (s.router.url.indexOf('quote-add') > -1) {
              if (objecttype == 'quoterequest') {
                forobj = 'for a Quote Request';
              } else {
                forobj = 'for a Bid';
              }
              screen = 'Bid';
            }
            if (requestForUpdate) {
              s.trackMatomoEvent("Marketplace Trading", "Request Update on Quote", "Request Update button on " + screen + " screen", null);            
            } else {
              s.trackMatomoEvent("Marketplace Trading", "Ask a Question", "Ask a Question button " + forobj + " on " + screen + " screen", null);            
            }
            var com = encodeURIComponent(eli.value);
            if (requestForUpdate) {
              com = "Request for Update: " + com + '&requestForUpdate=1';
            }
            var body = objecttype + 'id=' + id + '&recipientCompany=' + recipientCompanyId +
              '&CommunicationType=' + com + 
              '&QuestionText=' + encodeURIComponent(elm.value) + '&AskingUserRole=' + s.currentUserRole;
              s.log(1,'body=' + body);
              s.executeCustomCommand('SubmitInformationRequest',body).then((result)=>{
                s.log(1,'*** "SubmitInformationRequest" returns: ' + JSON.stringify(result));
                if (p) {
                  //execute 'refresh' function if page object was provided
                  p.ionViewWillEnter();
                }
              }).catch(error=>{
                s.log(2,'*** error returned from SubmitInformationRequest" call error: ' + JSON.stringify(error));
                if (ele) {
                  ele.innerHTML = "Error happened during information request submission. Please try again later.";
                  return false;            
                }
              }); 
          } else {
            if (ele) {
              ele.innerHTML = "Please enter request message";
              return false;            
            }
          }
        }
      },
      {
        text: 'Cancel'
      }
    ]
    }).then(alert=> {
      alert.present();
    });     
  }

  // qs below is one of: quoterequestid=NN or quoteid=NN or orderid=NN
  answerQuestion(questionId, recipientCompanyId, qs, p: any = null) {
    let s = this;
    s.log(1,'answerQuestion');
    const alert = this.alertController.create({
      //header: 'Request Info',
      message: '<h3>Answer Question</h3>' + 
      '<div>' + 
        '<textarea id="infomessage" name="infomessage" rows="6" cols="20" placeholder="Enter you answer here..."></textarea>'+
      '<div id="AlertErrorBox" class="messageError"></div></div>'
      ,
      animated: true,
      cssClass: 'lightBox',
      buttons: [{
        text: 'Submit Answer',
        handler: () => {
          let elm: any =  document.getElementById('infomessage');
          let ele: any =  document.getElementById('AlertErrorBox');
          if (elm.value) {
            let screen = 'Order';
            if (s.router.url.indexOf('quote-request') > -1) {
              screen = 'Quote Request';
            } else if (s.router.url.indexOf('quote/') > -1 || s.router.url.indexOf('quote-add') > -1) {
              screen = 'Quote';
            }
            s.trackMatomoEvent("Marketplace Trading", "Answer Question", "Answer button on " + screen + " screen", null);
            var body = qs +  '&id=' + questionId + '&recipientCompany=' + recipientCompanyId +'&AnswerText=' + encodeURIComponent(elm.value) + 
              '&role=' + s.currentUserRole;
              //s.log(1,'answerQuestion body=' + body);
              s.executeCustomCommand('SubmitInformationRequest',body).then((result)=>{
                s.log(1,'*** "answerQuestion" returns: ' + JSON.stringify(result));
                if (p) {
                  //execute 'refresh' function if page object was provided
                  p.ionViewWillEnter();
                }
              }).catch(error=>{
                s.log(2,'*** error returned from answerQuestion" call error: ' + JSON.stringify(error));
                if (ele) {
                  ele.innerHTML = "Error happened during information request submission. Please try again later.";
                  return false;            
                }
              }); 
          } else {
            if (ele) {
              ele.innerHTML = "Please enter an answer";
              return false;            
            }
          }
        }
      },
      {
        text: 'Cancel'
      }
    ]
    }).then(alert=> {
      alert.present();
    });     
  }

  quoteRequestNotInStock(id) {
    return this.executeCustomCommand('SellerQuoteRequestNotInStock','id=' + id );
  }
  
roundRating(rating) {
  return (Math.floor(2*rating + 0.4))/2;
}

  GetQuoteRowImages(images,CompanyID,QuoteID,RowID) {
    ///images/marketplace/companies/cid/quote_id/rid/resized/unique_name.jpg
    this.log(1,'GetQuoteRowImages: images=' + images + ' CompanyID=' + CompanyID + ' QuoteID=' + QuoteID + ' RowID=' + RowID);
    var arrImages = [];
    if (images) {
      // create list of image tags to display images from the server
      let parts = images.split(',');
      let url0 = this.sourceHost + '/images/marketplace/companies/' + CompanyID + '/quote_' + QuoteID + '/' + RowID + '/resized/';
      for (let i=0;i<parts.length;i++) {
        let url = url0 + parts[i].trim();
        arrImages.push(url);
      }
    }
    return arrImages;
  }

  fcmSubscribe(topic) {
    /*
    this.log(1,'fcmSubscribe this.useFirebase=' + this.useFirebase + ' this.platformIsCordova' + this.platformIsCordova + 'SubsribedToFirebaseTopics=' + this.currentUserData.SubsribedToFirebaseTopics + ' topic=' + topic);  
    if (this.useFirebase && this.platformIsCordova && this.currentUserData.SubsribedToFirebaseTopics.indexOf(topic) == -1) {
      this.trackMatomoEvent("Interaction", "Notification", "Subscribed to Push Notification Topic " + topic, null);
      this.log(0,'fcmSubscribe subscribe to topic: ' + topic);
      this.firebaseMessaging.subscribe(topic);
      this.currentUserData.SubsribedToFirebaseTopics.push(topic);
      this.currentUserDataChanged = true;
    } 
    */
  }

  fcmUnsubscribe(topic) {
    /*
    this.log(1,'fcmUnsubscribe this.useFirebase='+ this.useFirebase + ' this.platformIsCordova' + this.platformIsCordova + 'SubsribedToFirebaseTopics=' + this.currentUserData.SubsribedToFirebaseTopics + ' topic=' + topic);
    if (this.useFirebase && this.platformIsCordova && this.currentUserData.SubsribedToFirebaseTopics.indexOf(topic) > -1) {
      this.trackMatomoEvent("Interaction", "Notification", "Unsubscribed from Push Notification Topic " + topic, null);    
      this.log(0,'fcmUnsubscribe unsubscribe from topic: ' + topic);
      this.firebaseMessaging.unsubscribe(topic);
      this.currentUserData.SubsribedToFirebaseTopics.splice(this.currentUserData.SubsribedToFirebaseTopics.indexOf(topic),1);
      this.currentUserDataChanged = true;
    } 
    */
  }

  /*
  setupFcmNotifications() {
    //
    this.log(1, "*** setupFcmNotifications ***");
    this.fcmSubscribe('all');   
    //Notifications
    if (this.platform.is('ios')) {
      // Grant permission to recieve push notifications
      //this.firebase.hasPermission().then(data=>{
        if (!this.firebaseHasPermissions) {
          //this.firebase.grantPermission();
          let t = this;
          this.firebaseMessaging.requestPermission().then(result => {
            this.firebaseHasPermissions = true;
            t.log(1, "setupFcmNotifications:  grantPermission result=" + result);
            // it doesn't look like result actually has any information about user choice
            //t.firebase.hasPermission().then(data=>{
            //  if (!data.isEnabled) {
                t.trackMatomoEvent("Interaction", "Notification", "Push Notification Permission Granted", null);
            //  } else {
            //    t.trackMatomoEvent("Interaction", "Notification", "Push Notification Permission Refused", null);
            //  }
            //})
          }).catch(error => {
            t.trackMatomoEvent("Interaction", "Notification", "Push Notification Permission Granting Error", null);
            t.log(2, "setupFcmNotifications:  grantPermission - ERROR:" + error);
          });
        }
      //})
      this.fcmSubscribe('ios');
    } else if (this.platform.is('android')) {
      this.fcmSubscribe('android');
    }

    this.firebaseMessaging.getToken().then(token=>{
      if (token) this.deviceToken = token;
      this.log(1, "setupFcmNotifications: getToken token=" + token);
    });

    this.firebaseMessaging.onTokenRefresh().subscribe(data=>{
      this.log(1, "Device token updated");
      this.firebaseMessaging.getToken().then(token=>{
        if (token) this.deviceToken = token;
        this.log(1, "setupFcmNotifications: getToken token=" + token);
      });  
    });

    this.firebaseMessaging.onBackgroundMessage().subscribe(data=>{
      // message received when app was in background
      this.trackMatomoEvent("Interaction", "Notification", "Push Notification Opened", null);
      let currentTime = new Date();
      this.log(1, "firebase onBackgroundMessage: this.server=" + this.server + " this.lastNotificationChecked=" + this.lastNotificationChecked);
      if (this.server && (this.lastNotificationChecked == null || this.timeDiffInMinutes(currentTime,this.lastNotificationChecked) > 1)) {
        this.checkServerForNotifications();
      }      
      this.log(1, "setupFcmNotifications: FCM notification received in background " + JSON.stringify(data) );
    });

    this.firebaseMessaging.onMessage().subscribe(data=>{
      // message received when app was in foreground
      //
      //Notification flow:
      //App is in foreground:
      //    User receives the notification data in the JavaScript callback 
      //    without any notification on the device itself 
      //    (this is the normal behaviour of push notifications, it is up to you, the developer, to notify the user)
      //App is in background:
      //    User receives the notification message in its device notification bar
      //    User taps the notification and the app opens
      //    User receives the notification data in the JavaScript callback
      //
      this.log(1, "FCM notification received data.wasTapped=" + data.wasTapped);
      this.trackMatomoEvent("Interaction", "Notification", "Push Notification Opened", null);
      let currentTime = new Date();
      this.log(1, "firebase onMessage: this.server=" + this.server + " this.lastNotificationChecked=" + this.lastNotificationChecked);
      if (this.server && (this.lastNotificationChecked == null || this.timeDiffInMinutes(currentTime,this.lastNotificationChecked) > 2)) {
        this.checkServerForNotifications();
      }
      //if(data.wasTapped){
      //  //Notification was received on device tray and tapped by the user.
      //  this.log(1, "setupFcmNotifications: FCM notification received in background " + JSON.stringify(data) );
      //  this._appTappedId = data.gcm_message_id; // this is to not show Apple message again
      //}else{
        //Notification was received in foreground. Maybe the user needs to be notified.
        if (data.gcm) {
          // android message
          this.dialogs.alert("App Notification:\n\n"  + data.gcm.title + "\n" + data.gcm.body)
            .then(() => this.log(1, 'Dialog dismissed'))
            .catch(e => this.log(1, 'Error displaying dialog ' + e));        
        } else if (data.aps && data.aps.alert) {
          //} else if (data.aps && data.aps.alert && data.gcm_message_id && data.gcm_message_id != this._appTappedId) {
            // ios message
          this.dialogs.alert("App Notification:\n\n"  + data.apps.alert.title + "\n" + data.apps.alert.body)
            .then(() => this.log(1, 'Dialog dismissed'))
            .catch(e => this.log(1, 'Error displaying dialog ' + e));   
        }   
        this.log(1, "setupFcmNotifications: FCM notification received in foreground " + JSON.stringify(data) );
      //}      
    })
    //
    //end notifications.
    //statusBar.styleDefault();
    //splashScreen.hide();    
  }
*/

  // Checks availability of internet connection.
  // It always returns true during browser testing. It allows both wifi and varioud data connection. 
  // That this function should be called after platform is ready
  isConnected(): boolean {
    let conntype = "unknown";
     this.log(0,'Settings isConnected: this.platformIsReady=' + this.platformIsReady + 
      ' this.platformIsCordova=' + this.platformIsCordova);
    let connected = false;
    if (this.platformIsCordova) {
      conntype = this.network.type;
      // check that we have internet/data connection. Should we check for wifi connection instead?
      if ((conntype && conntype !== 'none')) {
        // for check in browser set connected to true when platform is not cordova
        connected = true;
      }
    } else {
      connected = true;
    }     
    
    this.log(1,'isConnected: connected=' + connected + ' conntype=' + conntype);    
    return connected;
  }

  // Checks availability of wifi internet connection.
  // It always returns true during browser testing. 
  isWifiConnected(): boolean {
    let conntype = this.network.type;
    this.log(0,'Settings isWifiConnected: this.platformIsReady=' + this.platformIsReady + 
      ' this.platformIsCordova=' + this.platformIsCordova);
    let connected = false;
    // check that we have internet/data connection. Should we check for wifi connection instead?
    if ((conntype == 'wifi') || !this.platformIsCordova) {
      // for check in browser set connected to true when platform is not cordova
      connected = true;
    }
    this.log(1,'isWifiConnected: connected=' + connected + ' conntype=' + conntype);    
    return connected;
  }

  log(logLevel, message) {
    if(logLevel >= this.logDisplayLevel) {
      console.log(this.formatDateTime("YYYY-MM-DD HH:mm:ss.SSS") + ": " + message);
    }
    if (logLevel >= this.errorLogMinLogLevelToKeep) {
      if (this.errorLogQueue.length >= this.errorLogMaxEntriesToKeep) {
        this.errorLogQueue.shift();
        //this.autoNotifyDeveloper();
      }
      this.errorLogQueue.push(this.formatDateTime("YYYY-MM-DD HH:mm:ss.SSS") + ": " + message);
    }
  }

  autoNotifyDeveloper() {
    // send email
    let msg = "Automatic Notification";
    let log = this.HtmlEncode(this.getLastErrorsAndCleanLog());
    let info = this.getUserAndDeviceInfoJson();

    let commandParameters = 'info=' + encodeURIComponent(info) +
      '&log=' + encodeURIComponent(log.replace(/&quot;/g,"'")) +
      '&msg=' + encodeURIComponent(msg);
    this.executeCommand('notifydeveloper', commandParameters)
      .subscribe((result: any) => { 
        this.log(1,'autoNotifyDeveloper http post result=' + JSON.stringify(result));
        if (result && result.Success) {
          this.trackMatomoEvent("Interaction", "Run", "Auto Notify Developers", "1");
        } else {
            this.log(2,'autoNotifyDeveloper error message=' + result.Message);
            this.trackMatomoEvent("Interaction", "Run", "Auto Notify Developers", "0");
        }
    },  error =>  {
        this.log(2,'autoNotifyDeveloper server error ' + JSON.stringify(error));
    });     
  } 

  public getLastErrorsAndCleanLog() {
    let errors = this.errorLogQueue.join("\n");
    this.errorLogQueue = [];
    return errors;
  }

  private initAnalytics(callback) {
    this.log(1,'initAnalytics: window=' + (<any>window));
    (<any>window).FirebasePlugin.initAnalytics();
    this.log(1,'initAnalytics: FirebasePlugin=' + (<any>window).FirebasePlugin);
    setTimeout(() => {
        if (callback) callback();
    }, 100);
  }

  trackMatomoEvent(category, action, name, someVal?) {
    if (this.isConnected() && this.trackMatomoAnalytics) {  
      this.matomoTracker.trackEvent(category, action, name, someVal);
      this.log(1,'trackMatomoEvent category=' + category + ' action=' + action + ' name=' + name);
    } else {
      this.log(0,'trackMatomoEvent - no analytics tracking');
    }
  }

  trackMatomoSearch(keywords, category) {
    if (this.isConnected() && this.trackMatomoAnalytics) {  
      this.matomoTracker.trackSiteSearch(keywords,category);
      this.log(1,'trackSiteSearch category=' + category + ' keywords=' + keywords);
    } else {
      this.log(0,'trackSiteSearch - no analytics tracking');
    }
  }

  trackMatomoLink(url) {
    if (this.isConnected() && this.trackMatomoAnalytics) {
      if (url && url.indexOf('#') > -1 && url.indexOf(this.sourceHost) == 0) {
        // restore format used in trackMatomoPageView
        url = this.sourceHost + url.split('#')[1];
      }      
      this.matomoTracker.trackLink(url,"link"); 
      this.log(1,'trackMatomoLink url=' + url);
    } else {
      //this.log(0,'trackMatomoLink - no analytics tracking');
    }    
  }

  trackMatomoDownload(url) {
    if (this.isConnected() && this.trackMatomoAnalytics) {
      if (url && url.indexOf('#') > -1 && url.indexOf(this.sourceHost) == 0) {
        // restore format used in trackMatomoPageView
        url = this.sourceHost + url.split('#')[1];
      }
      this.matomoTracker.trackLink(url,"download"); 
      this.log(1,'trackMatomoDownload url=' + url);
    } else {
      //this.log(0,'trackMatomoLink - no analytics tracking');
    }    
  }

  trackMatomoPageView(pageName) {
    if (this.isConnected() && this.trackMatomoAnalytics) {
      //let url = "https://" + this.appTextId + ".app/" + pageName.toLowerCase().replace(" (dynamic page)","").replace(/[^a-z0-9\s]+/g," ").trim().replace(/\s+/g,"_");
      let url = this.router.url;
      if (window.location.href && window.location.href.indexOf('#') > -1) {
        url = window.location.href.split('#')[1];
      }
      url = this.sourceHost + url;
      this.matomoTracker.setCustomUrl(url);
      this.matomoTracker.setDocumentTitle(pageName);
      //this.matomoTracker.setGenerationTimeMs(0);
      this.matomoTracker.trackPageView(); 
      this.log(1,'trackMatomoPageView pageName=' + pageName + ' url=' + url);
    } else {
      this.log(0,'trackMatomoPageView - no analytics tracking');
    }
  }

  trackPageView(pageName) {
    this.trackMatomoPageView(pageName);
    if (this.isConnected() && this.platformIsCordova && this.trackFirebaseAnalytics) {
      this.firebase.setScreenName(pageName).then((result)=>{
        this.log(0,'trackPageView firebase setScreenName: ' + pageName + ' result=' + result)
      })
      .catch(e => {
        this.log(0,'trackPageView 1 Error in firebase.setScreenName ' + e);
        if (e == "Analytics isn't initialised") {
          this.initAnalytics(() => this.trackPageView(pageName));
        }  else {
          this.log(2,'trackPageView 2 Error in firebase.setScreenName ' + e);
        }      
      });
    } else {
      this.log(0,'trackPageView - no firebase analytics tracking');
    }
  }

  abbreviate(text, len) {
    let abbr = ' -empty- ';
    if (text) {
      abbr = text;
      if (text.length > len) {
        abbr = text.substring(0, len) + '...';
      }
    }
    return abbr;
  }

  capitalize(text) {
    let cap = "";
    if (text) {
      cap = text.replace(/(?:^|\s)\S/g, function (a) { 
        return a.toUpperCase(); 
      });
    }
    return cap;
  }

  //
  public getSecureStorage(id?) {
    var t = this;
    t.log(1,'getSecureStorage: t.secureStorageInitialising=' + t.secureStorageInitialising + " id=" + id);
    // GR 202000415 Secure Storage commands below behaves crazy. If there are multiple calls to
    // this function at the same time only one returns anything. Also very first call to 
    // secureStorage.create returns not a promise but null. So we work around those problems by
    // making sure that only first calls to the function proceeds and others are waiting in a loop.
    // Also there is a special code to check for returning nulls here and in getSecureKey
    if (t.secureStorageInitialising) {
      return new Promise(
        function (resolve, reject) {
          setTimeout(function() {
            t.log(1,'getSecureStorage: timeout for id=' + id);
            resolve(t.getSecureStorage(id));
          }, 300); 
        })    
    } else {
      t.log(1,'getSecureStorage: new Promise t.ss=' + t.ss + ' t.secureStorageInitialising=' + t.secureStorageInitialising);
      return new Promise(
        function (resolve, reject) {
          t.secureStorageInitialising = true;
          if (t.ss == null) {
            t.platform.ready().then(() => {
              //t.storage.ready().then(() => {
                //t.platformIsCordova = (t.platform.is('ios') || t.platform.is('android'));
                t.log(1,'getSecureStorage: storage ready driver=' + t.storage.driver + ' t.platformIsCordova=' + t.platformIsCordova);
                if (t.platformIsCordova) {
                  //t.platformIsCordova = true;
                  let secureStorage = new SecureStorageEcho(); 
                  t.log(1,'getSecureStorage before create t.ss=' + t.ss + " id=" + id); 
                  let ssPromise = secureStorage.create(t.appTextId + '_app_sec');
                  t.log(1,'settings getSecureStorage ssPromise=' + ssPromise);    
                  if (ssPromise) {       
                    ssPromise.then((secStorage: SecureStorageEchoObject) => {
                      t.log(1,'getSecureStorage Storage Created');
                      t.ss = secStorage;
                      t.initialiseSecureStorageError = false;
                      t.secureStorageInitialising = false;
                      resolve(t.ss);
                    }).catch((error)=>{
                      // should we either use plain storage here (easy - see 'Not Cordova' below) 
                      // or try to use server automatically (may be trickier)?
                      t.initialiseSecureStorageError = true;
                      t.log(2,'Settings: getSecureStorage create error ' + JSON.stringify(error, Object.getOwnPropertyNames(error)));
                      t.secureStorageInitialising = false;
                      reject(new Error('secure storage error ' + JSON.stringify(error, Object.getOwnPropertyNames(error))));
                    });  
                  } else {
                    t.initialiseSecureStorageError = true;
                    t.secureStorageInitialising = false;
                    reject(new Error('secureStorage.create returns null'));
                  }
                } else {
                  // this is for browser testing only
                  t.log(1,'Not Cordova: Loading plain storage instead of secure one');
                  t.ss = t.storage;
                  t.initialiseSecureStorageError = false;
                  t.secureStorageInitialising = false;
                  resolve(t.ss);
                }
              //}).catch((error)=>{
              //  t.log(2,'Settings: storage ready error ' + JSON.stringify(error, Object.getOwnPropertyNames(error)));
              //  t.secureStorageInitialising = false;
              //  reject(new Error('storage error ' + JSON.stringify(error, Object.getOwnPropertyNames(error))));
              //});
            });
          } else {
            t.secureStorageInitialising = false;
            resolve(t.ss);
          }
        }
      );  
    } 
  }

  // authorise user after login to web site
  // GR 20200413 - we have some logic bellow when if appkeys stored in secure storage have the same usertoken then deviceid stored there
  // (not the one which was passed to the function!) will be used. We will need to send app update request after we know deviceid. 
  // That is why we pass update object.
  authoriseUser(cryptokey, usertoken, deviceid, update) {
    this._authorised = true;
    // GR we want to do it because in case when we authorise user again we want to
    // allow using app without forcing to reset PIN
    this._authenticated = true; 
    this.lastAccessed = new Date();
    this.userToken = usertoken;    
    this.log(1,'authoriseUser: user authorised and authenticated! this.deviceId=' + this.deviceId + ' deviceid=' + deviceid);  
    if (!this.deviceId) {
      this.deviceId = deviceid;
    }
    this.currentCryptoKey = cryptokey;
    this.log(1,'authoriseUser: user authorised and authenticated! this.lastAccessed=' + this.lastAccessed + ' deviceid=' + deviceid);      
      // save data and redirect to next step. That is required only for the first authorisation !
      var t = this;
      var appKeys = usertoken + ';' + deviceid; 
      if (!t.initialiseSecureStorageError) {
        this.saveEncryptionKey(cryptokey);
        this.log(0,'authorise after saveEncryptionKey');
      } else {
        this.setKey('plainappkeys',appKeys);
        this.log(0,'authorise after setKey appkeys');
      }
      this.setKey('userwasauthorised','1');
      t.log(1,'authoriseUser deviceid=' + deviceid + ' initialiseSecureStorageError=' + t.initialiseSecureStorageError);
      
      //t.configureMatomoUser();
  
      t.fcmSubscribe('authorised');
      t.fcmUnsubscribe('unauthorised');  
      if (!t.platformIsCordova) {
        let topic = 'browser';
        // that is a "hack" to be able to choose "browser" for in app notifications (push will not work!)
        if (t.currentUserData.SubsribedToFirebaseTopics.indexOf(topic) == -1) {
          t.log(1,'1 "subscribe" to topic: ' + topic);
          //this.firebase.subscribe(topic);
          t.currentUserData.SubsribedToFirebaseTopics.push(topic);
          t.currentUserDataChanged = true;
        } 
        t.log(1,'SubsribedToFirebaseTopics.indexOf=' + t.currentUserData.SubsribedToFirebaseTopics.indexOf(topic));
      }     
  
      this.getSecureKey('appkeys').then((appkeys:string)=>{
        let needToStoreKeys = true;
        t.log(0,'authoriseUser: got appkeys');
        if (appkeys && appkeys.indexOf(';') > -1) {     
          let parts = appkeys.split(';');
          let storedUsertoken = parts[0];
          let storedDeviceid = parts[1];
          if (storedUsertoken == usertoken) {
            needToStoreKeys = false;
            this.deviceId = storedDeviceid;
          }
        }
        t.log(1,'authoriseUser needToStoreKeys=' + needToStoreKeys + ' deviceid=' + deviceid + ' this.deviceId=' + this.deviceId);
        if (needToStoreKeys) {
          this.deviceId = deviceid;    
          this.setSecureKey('appkeys', appKeys);
          t.log(1,'authoriseUser after setSecureKey 1');
        }
        if (update) {
          update.checkAppUpdatesInBackground();
        }
      }).catch((error)=>{
        t.log(2,'authoriseUser: getSecureKey error reading keys ' + JSON.stringify(error));
        if (update) {
          update.checkAppUpdatesInBackground();
        }      
        t.deviceId = deviceid;    
        t.setSecureKey('appkeys', appKeys);
        t.log(1,'authoriseUser after setSecureKey 2');      
      });    
  }

  userAuthorised() {
    var t = this;
    return new Promise(
      function (resolve, reject) {
        if (t.appType == 'public') {
          t._authorised = true;
        }         
        t.log(1,'userAuthorised: t.appType=' + t.appType + ' t._authorised=' + t._authorised + ' t.initialiseSecureStorageError=' + t.initialiseSecureStorageError);
        if (t._authorised == null) {
          t.authorisationErrorMessage = "";
          if (t.initialiseSecureStorageError) {
            t._authorised = false;
            resolve(t._authorised);
          } else {
            return t.getSecureKey('appkeys').then((appkeys:string)=>{
              t.log(1,'userAuthorised: appkeys=' + appkeys);
              if (appkeys && appkeys.indexOf(';') > -1) {
                let parts = appkeys.split(';');            
                t.userToken = parts[0];
                t.deviceId = parts[1];              
                t._authorised = true;
                t.log(1,'userAuthorised: t.deviceId=' + t.deviceId);

                return t.getSecureKey('userdata').then((userdata:string)=>{
                  t.log(1,'userAuthorised: userdata=' + userdata);
                  if (userdata && userdata.indexOf('{') == 0) {
                    t.log(1,'userAuthorised: set currentUserData platformIsCordova=' + t.platformIsCordova);
                    t.currentUserData = JSON.parse(userdata);

                    if (!t.platformIsCordova) {
                      let topic = 'browser';
                      // that is a "hack" to be able to choose "browser" for in app notifications (push will not work!)
                      if (t.currentUserData.SubsribedToFirebaseTopics.indexOf(topic) == -1) {
                        t.log(1,'2 "subscribe" to topic: ' + topic);
                        //t.firebase.subscribe(topic);
                        t.currentUserData.SubsribedToFirebaseTopics.push(topic);
                        t.currentUserDataChanged = true;
                      } 
                      t.log(1,'SubsribedToFirebaseTopics.indexOf=' + t.currentUserData.SubsribedToFirebaseTopics.indexOf(topic));
                    } 

                    t.getAppVersion();
                    //t.configureMatomoUser(); 
                  }
                  t.log(1,'userAuthorised: before resolve 1 t._authorised=' + t._authorised);
                  resolve(t._authorised);
                }).catch((error)=>{
                  // GR - we should not get to here ! - that's old storage place
                  t.log(2,'userAuthorised: error getting userdata: ' + JSON.stringify(error));
                  // GR this may happen for the first access after updates
                  return t.getSecureKey('userproperties').then((userproperties:string)=>{
                    t.log(1,'userAuthorised: userproperties=' + userproperties);
                    if (userproperties && userproperties.indexOf(';') > -1) {
                      let parts = userproperties.split(';');            
                      t.currentUserData.Firstname = parts[0];
                      t.currentUserData.Lastname = parts[1];                      
                      t.currentUserData.Email = parts[2];   
                      if (parts.length > 3) {
                        t.currentUserData.Username = parts[3];
                      } 
                      if (parts.length > 4) {
                        t.currentUserData.Userid = parts[4];
                      } 
                      // save user data in new format
                      t.setSecureKey('userdata', JSON.stringify(t.currentUserData));
                      t.currentUserDataChanged = false;
                      t.log(1,'userAuthorised: before resolve 2 t._authorised=' + t._authorised);
                      resolve(t._authorised);
                    }
                  }).catch((error)=>{
                    t.log(1,'userAuthorised: error getting userproperties: ' + JSON.stringify(error));
                    resolve(t._authorised);
                  });                  
                });

              } else {
                // appkeys read from storage are blank or not valid
                t._authorised = false;
                t.authorisationErrorMessage = "Can not read data from the storage. Please reauthenticate the App.";
                t.log(1,'userAuthorised: before resolve 3 t._authorised=' + t._authorised);
                resolve(t._authorised);
              }
              //resolve(t._authorised);
            }).catch((error)=>{
              // can not read apkey 
              let strError = JSON.stringify(error);
              t.log(1,'userAuthorised: error: ' + strError + ' authorisationErrorMessage=' + t.authorisationErrorMessage);
              if (strError.indexOf('plainappkeys') == -1) { // that will come on initial App install (for some phones). We don't want to show it.
                t.authorisationErrorMessage = strError;
              }
              t.log(1,'userAuthorised: indexOf=' + strError.indexOf('plainappkeys') + ' authorisationErrorMessage=' + t.authorisationErrorMessage);
              //reject(error);
              t._authorised = false;
              t.log(1,'userAuthorised: before resolve 4 t._authorised=' + t._authorised);
              t.getKey('userwasauthorised').then((value:string)=>{
                if (!value) {
                  // Do NOT display storage error message until user is authorised
                  t.authorisationErrorMessage = '';
                }
                resolve(t._authorised);
              }).catch((error)=>{
                t.authorisationErrorMessage = '';
                resolve(t._authorised);
              })
            });
          }
        } else {     
          // return existing value
          t.log(1,'userAuthorised: before resolve 5 t._authorised=' + t._authorised);
          resolve(t._authorised);
        }        
      }
    );
  }

  get userAuthenticated():boolean{
    let currentTime = new Date();
    let timeDiff = this.timeDiffInMinutes(currentTime, this.lastAccessed);
    this.log(1,'userAuthenticated: timeDiff=' + timeDiff + ' currentTime=' + currentTime + ' lastAccessed=' + this.lastAccessed + ' sessionTimeInMinutes=' + this.sessionTimeInMinutes);
    this.log(1,'userAuthenticated: this.passwordAuthentication=' + this.passwordAuthentication + ' passwordSessionTimeInMinutes=' + this.passwordSessionTimeInMinutes);
    if (timeDiff > this.passwordSessionTimeInMinutes || (!this.passwordAuthentication && timeDiff > this.sessionTimeInMinutes)) {
      this._authenticated = false;
    } else {
      //this.userAuthenticated = true;
    }
    if (this.appType == 'public') {
      this._authenticated = true;
    }      
    this.log(1,'userAuthenticated: this.appType=' + this.appType + ' this._authenticated=' + this._authenticated);
    return this._authenticated;
  }

  set userAuthenticated(au){
    this._authenticated = au;
    if (this._authenticated) {
      this.lastAccessed = new Date();
    }
    this.log(1,'userAuthenticated: this._authenticated=' + this._authenticated + ' this.lastAccessed=' + this.lastAccessed);
  }

  // save key to secure storage (and temporary variable)
  saveEncryptionKey(keyvalue) {
    this.currentCryptoKey = keyvalue;
    this.log(1,'saveEncryptionKey this.ss=' + this.ss);
    this.getSecureStorage().then((secstor: Storage)=>{
      secstor.set('cryptokey', keyvalue);
    })  
  }

  // this function is to run from real cordova app after user session expire (600 minutes as default). It will create new user session
  // on the server and send back new sessionid and other user data. It emulates user session brhaviour on a site except that authentication
  // from the app is authomatic with the help of JWT token.
  reAuthenticateUser() {
    let t = this;
    return new Promise(
      function (resolve, reject) {    
        let reqestOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/x-www-form-urlencoded'
            })
          };
        let body: any = "c=reauthorise&t=" + t.userToken;
        t.log(1,'reAuthenticateUser before post serverApiUrl=' + t.serverApiUrl + " t.platformIsCordova=" + t.platformIsCordova);
        t.getJwtToken(t.serverApiUrl,'').then(token=>{
          let url = t.serverApiUrl + '?jwt=' + token;
          t.http.post(url, body, reqestOptions).subscribe((result: any) => { 
            t.log(1,'reAuthenticateUser: authorise http post result=' + JSON.stringify(result));
            if (result && result.Success) {
              t.trackMatomoEvent("Management", "ReAuthenticate", "App Authentication Success", null);
              t.updateCurrentUserData(result);

              t.userAuthenticated = true;      
              resolve(true);
            } else {
              t.trackMatomoEvent("Management", "ReAuthenticate", "App Authentication Failure", null);
              resolve(false);
            }
          },  error =>  {
              t.trackMatomoEvent("Management", "ReAuthenticate", "App Authentication Error", null);
              t.log(2,'reAuthenticateUser server error ' + error);
              reject(new Error('Can not communicate with the server. Check network connection.'));
          });
        }).catch(error=>{
          reject(new Error('reAuthenticateUser Can not create JWT token.'));                        
        })
    });
  }

  setCookie(cname, cvalue, exminutes) {
    const d = new Date();
    d.setTime(d.getTime() + (exminutes*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // check if browser user is already logged in. Used from update service for auto login
  checkBrowserAuthentication(update) {
    let t = this;
    t.log(1,'checkBrowserAuthentication start t.platformIsCordova=' + t.platformIsCordova);
    return new Promise(
      function (resolve, reject) {    
        if (t.passwordAuthentication && !t.platformIsCordova) {
          let sid = t.getCookie('iobsid');
          t.log(1,'checkBrowserAuthentication sid=' + sid);
          if (sid) {
            t.executeCommand('authorise', "u=auto" + "&sid=" +  encodeURIComponent(sid)).subscribe((result: any) => {  
              t.log(1,'checkBrowserAuthentication: authorise http post result=' + JSON.stringify(result));
              if (result && result.Success) {
                t.trackMatomoEvent("Management", "Authenticate After Browser Reset", "App Authentication Success", null);
                t.log(1,"before authoriseUser: result.Data.sid=" + result.Data.sid);
                if (!t.deviceId) {
                  // we don't update deviceid if it was set before
                  t.deviceId = result.Data.deviceid;
                }
                let urole = t.getCookie('iobrole');
                if (urole) {
                  t.currentUserRole = urole;
                }
                // save keys and authorise user. Do we need to run it if cryptokey, usertocken, deviceid didn't change (after first login) ?
                t.authoriseUser(result.Data.cryptokey, result.Data.usertoken, t.deviceId, update);
                t.updateCurrentUserData(result);
    
                //this.navCtrl.setRoot('AuthoriseAddPinPage');
                t.userAuthenticated = true;   
                let url = '/home';
                if (t.lastClickedUrl) {
                  url = t.lastClickedUrl;
                }
                t.log(1,'checkBrowserAuthentication url=' + url + ' t.lastClickedUrl=' + t.lastClickedUrl);                  
                t.openPage(url);
                resolve(true);
              } else {
                t.trackMatomoEvent("Management", "Authenticate With Username", "App Authentication Failure", null);
                resolve(false);
              }
            },  error =>  {
                t.trackMatomoEvent("Management", "Authenticate After Browser Reset", "App Authentication Error", null);
                t.log(2,'checkUserAuthentication server error ' + error);
                reject(new Error('Can not communicate with the server. Check network connection.'));
            });              
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
    });
  }


  // check provided u & p
  checkUserAuthentication(email,password,update) {
    let t = this;
    return new Promise(
      function (resolve, reject) {    
        t.executeCommand('authorise', "u=" + encodeURIComponent(email) + "&p=" + 
        encodeURIComponent(password)).subscribe((result: any) => {  
          t.log(1,'checkUserAuthentication: authorise http post result=' + JSON.stringify(result));
          if (result && result.Success) {
            t.trackMatomoEvent("Management", "Authenticate With Username", "App Authentication Success", null);
            t.log(1,"before authoriseUser: result.Data.firstname=" + result.Data.firstname);
            if (!t.deviceId) {
              // we don't update deviceid if it was set before
              t.deviceId = result.Data.deviceid;
            }
            if (!t.platformIsCordova) {
              // save cookie value which may be used in case of browser reload
              t.setCookie('iobsid', result.Data.sid, t.passwordSessionTimeInMinutes);
            }
            // save keys and authorise user. Do we need to run it if cryptokey, usertocken, deviceid didn't change (after first login) ?
            t.authoriseUser(result.Data.cryptokey, result.Data.usertoken, t.deviceId, update);
            t.updateCurrentUserData(result);

            //this.navCtrl.setRoot('AuthoriseAddPinPage');
            t.userAuthenticated = true;
            t.log(1,'checkUserAuthentication authentication success for email=' + email);        
            resolve(true);
        } else {
          t.trackMatomoEvent("Management", "Authenticate With Username", "App Authentication Failure", null);
          resolve(false);
        }
      },  error =>  {
          t.trackMatomoEvent("Management", "Authenticate With Username", "App Authentication Error", null);
          t.log(2,'checkUserAuthentication server error ' + error);
          reject(new Error('Can not communicate with the server. Check network connection.'));
      });
    });
  }

  updateCurrentUserData(result) {
    let t = this;
    t.currentCryptoKey = result.Data.cryptokey;

    t.currentUserData.Firstname = result.Data.firstname;
    t.currentUserData.Lastname = result.Data.lastname;
    t.currentUserData.Username = result.Data.username;
    t.currentUserData.Userid = result.Data.userid;
    if (t.currentActivationEmail) {
      t.currentUserData.Email = t.currentActivationEmail;
    } else {
      t.currentUserData.Email = result.Data.email;
    }

    t.currentUserData.SessionId = result.Data.sid;
    t.currentUserData.CompanyId = result.Data.CompanyId;
    t.currentUserData.CompanyName = result.Data.CompanyName;
    t.currentUserData.ABN = result.Data.ABN;
    t.currentUserData.CompanyLogo = result.Data.CompanyLogo;
    t.currentUserData.CompanyAddress = result.Data.CompanyAddress;
    t.currentUserData.CompanyCity = result.Data.CompanyCity;
    t.currentUserData.CompanyPostcode = result.Data.CompanyPostcode;
    t.currentUserData.CompanyState = result.Data.CompanyState;
    t.currentUserData.CompanyCountry = result.Data.CompanyCountry;
    t.currentUserData.CompanyPhone = result.Data.CompanyPhone;
    t.currentUserData.CompanyEmail = result.Data.CompanyEmail;
    t.currentUserData.capRole = result.Data.CapRole;
    if (t.currentUserData.capRole && t.currentUserData.capRole.toLowerCase() == 'public') {
      t.currentUserData.capRole = 'buyer';
      t.currentUserData.capPublicUser = true;
    }
    if (result.Data.SecurityLevel && result.Data.SecurityLevel == "2") {
      t.userIsAdmin = true;
    } else {
      t.userIsAdmin = false;
    }
    t.log(1,"updateCurrentUserData: result.Data.CapRole=" + result.Data.CapRole + " t.currentUserData.capRole=" + t.currentUserData.capRole + " t.currentUserData.Email=" + t.currentUserData.Email);
    t.log(1,"updateCurrentUserData: result.Data.SecurityLevel=" + result.Data.SecurityLevel + " t.userIsAdmin=" + t.userIsAdmin + " sid=" + t.currentUserData.SessionId);

    t.setCurrentUserRole();

    t.events.publishData({updatefinished: true});

    t.currentUserData.capPromoted = t.getBoolean(result.Data.CapPromoted);
    t.currentUserData.capDatalookup = t.getBoolean(result.Data.CapDatalookup);
    t.currentUserData.capCompanyDisplayName = result.Data.CapCompanyDisplayName;
    t.currentUserData.capCompanyInfo = result.Data.CapCompanyInfo;
    t.currentUserData.capFeatureImage = result.Data.CapFeatureImage;
    t.currentUserData.capImages = result.Data.CapImages;
    t.currentUserData.capMemberSince = result.Data.CapMemberSince;
    t.currentUserData.capPurchases = result.Data.CapPurchases;
    t.currentUserData.capSales = result.Data.CapSales;
    t.currentUserData.capCancellations = result.Data.CapCancellations;
    t.currentUserData.capAverageReplyTime = result.Data.CapAverageReplyTime;

    t.log(1,"updateCurrentUserData: result.Data.CapDatalookup=" + result.Data.CapDatalookup + " t.currentUserData.capDatalookup=" + t.currentUserData.capDatalookup);
    t.log(1,"updateCurrentUserData: result.Data.CapPromoted=" + result.Data.CapPromoted + " t.currentUserData.capPromoted=" + t.currentUserData.capPromoted);

    t.currentUserData.capConditions = result.Data.CapConditions;
    t.currentUserData.capMakes = result.Data.CapMakes;
    t.currentUserData.capContactAfterAuction = result.Data.CapContactAfterAuction;
    t.currentUserData.capCompanyRating = result.Data.CapCompanyRating;        
    
    t.currentUserData.UserDataLastUpdated = new Date();
    
    t.log(0,"before setSecureKey: result.Data.firstname=" + result.Data.firstname + " t.currentUserData.Firstname=" + t.currentUserData.Firstname);
    t.setSecureKey('userdata', JSON.stringify(t.currentUserData));
    t.log(1,"after setSecureKey: result.Data.firstname=" + result.Data.firstname + " t.currentUserData.Firstname=" + t.currentUserData.Firstname + ' t.currentUserRole=' + t.currentUserRole + ' t.switchRolesEnabled=' + t.switchRolesEnabled);
    t.currentUserDataChanged = false;
  }

  setCurrentUserRole() {
    let t = this;
    t.log(1,"setCurrentUserRole: capRole=" + t.currentUserData.capRole);
    if (t.currentUserData.capRole == 'trader') {
      if (!t.currentUserRole) {
        t.currentUserRole = 'seller';
        if (!t.platformIsCordova) {
          t.setCookie('iobrole', t.currentUserRole, t.passwordSessionTimeInMinutes);
        }
      }
      t.switchRolesEnabled = true;
    } else {
      t.currentUserRole = t.currentUserData.capRole;
      t.switchRolesEnabled = false;
    }    
  }

  switchType() {
    let prevrole = this.currentUserRole;
    if (this.currentUserRole == "buyer") {
      this.currentUserRole = "seller";
      this.trackMatomoEvent("Marketplace Trading", "Switch View", "Switch to Seller view button", null);
    } else {
      this.currentUserRole = "buyer";
      this.trackMatomoEvent("Marketplace Trading", "Switch View", "Switch to Buyer view button", null);
    }
    if (this.isConnected() && this.trackMatomoAnalytics) {                     
      this.matomoTracker.setCustomVariable(5,"role",this.currentUserRole,"page");                    
    }      
    if (!this.platformIsCordova) {
      this.setCookie('iobrole', this.currentUserRole, this.passwordSessionTimeInMinutes);
    }
    this.log(1,'switchType: currentUserRole=' + this.currentUserRole);
    this.events.publishData({updatefinished: true});
    //this.events.publishData({userroleswitchedfrom: prevrole}); 
    if (this.router.url != '/home') {
      this.openPage('/home');
    } else{
      this.openPage('/home;role=' + this.currentUserRole);
    }   
  }

  pageReload(p) {
    var els = document.querySelectorAll('.mainPartialContent,.mainContent');
    if (els && els.length > 0) {
      for (let i=0; i<els.length; i++) {
        els[i].className = els[i].className.replace(' visible','');
        els[i].className += ' hidden';
        setTimeout(()=>{els[i].className = els[i].className.replace(' hidden',' visible');},200);
      }
    }
    p.ionViewWillEnter();     
  }

/*
    return this.getSecureKey('userpin').then(
      (storedpin:string)=>{
        if (storedpin == pin) {
          this._authenticated = true;  
          this.lastAccessed = new Date();         
          //this.fcmSubscribe('authorised');
          //this.fcmUnsubscribe('unauthorised');
        } else {
          this._authenticated = false;
        }
        //this.log(1,'checkAuthentication pin=' + this.abbreviate(pin,1) + " stored=" + this.abbreviate(storedpin,1) + "... _authenticated=" + this._authenticated);
        this.log(1,'checkAuthentication  _authenticated=' + this._authenticated);
        return this._authenticated;
      }
    ).catch((error)=>{
      this.log(1,'*** checkAuthentication getSecureKey error: ' + error.message);
    });
  }  */
  
  // check provided pin with previously stored one
  checkAuthentication(pin) {

    let t = this;
    return new Promise(
      function (resolve, reject) {    
        t.getSecureKey('userpin').then((storedpin:string)=>{
          if (storedpin == pin) {
            t._authenticated = true;  
            t.lastAccessed = new Date();         
            //t.fcmSubscribe('authorised');
            //t.fcmUnsubscribe('unauthorised');
  
            let currentTime = new Date();
            let timeDiff = null;    
            if (t.currentUserData.UserDataLastUpdated != null) {
              timeDiff = t.timeDiffInMinutes(currentTime, t.currentUserData.UserDataLastUpdated);
            }
            t.log(1,'checkAuthentication  t.passwordSessionTimeInMinutes=' + t.passwordSessionTimeInMinutes + ' timeDiff=' + timeDiff);
            if (timeDiff != null && timeDiff > t.passwordSessionTimeInMinutes) {
              t.log(1,'checkAuthentication  before call to reAuthenticateUser');
              t.reAuthenticateUser().then((au: boolean)=>{
                t.log(1,'reAuthenticateUser: au=' + au);
                t._authenticated = au;
                resolve(au);
              }).catch(error=>{
                // GR. We have occasional COM errors with authentication. If PIN authentication will fail here
                // it will look very strange. Should we ignore the error (if it happens) below?
                t.log(2,'checkAuthentication  reAuthenticateUser error=' + JSON.stringify(error));
                resolve(false);
              });
            } else {
              resolve(true);
            }
          } else {
            t._authenticated = false;
            resolve(false);
          }
          //t.log(1,'checkAuthentication pin=' + t.abbreviate(pin,1) + " stored=" + t.abbreviate(storedpin,1) + "... _authenticated=" + t._authenticated);
          t.log(1,'checkAuthentication  _authenticated=' + t._authenticated);
          //return t._authenticated;
      },  error =>  {
          t.log(1,'*** checkAuthentication getSecureKey error: ' + error.message);
          reject(new Error('Can not find stored PIN. You may need to reauthenticate.'));
      });
    });
  }

  readCurrentAppJson() {
    var t = this;
    return new Promise<object>((resolve, reject) => {
      if (t.currentAppData) {
        t.log(1,'readCurrentAppJson got appdata from memory'); // appdata=' + t.currentAppData + ' s=' +  JSON.stringify(t.currentAppData));
        resolve(t.currentAppData);
      } else {
        t.log(1,'readCurrentAppJson before reading appdata from key');
        t.getSecureKey('appdata').then((appdata:string)=>{
          //let needToStoreKeys = true;
          if (appdata) {
            t.log(1,'readCurrentAppJson got appdata from secure key appdata=' + t.abbreviate(appdata,500));
            t.currentAppData = JSON.parse(appdata);
          } else {
            t.log(2,'*** readCurrentAppJson EMPTY appdata from secure key');
            //t.currentAppData = {};
          }
          resolve(t.currentAppData);
        }).catch((error)=>{
          t.log(1,'readCurrentAppJson can not read appdata from secure key. Try reading from file platformIsCordova=' + t.platformIsCordova);   
          if (t.platformIsCordova) {
            // GR This is the first time when app runs. We subscribe to "unauthorised" topic here
            // and we will unsubscribe to that topic after authorisation or authentication
            // Note that it will not work for people who already installed App (: before
            t.fcmSubscribe('unauthorised');   
            t.trackMatomoEvent("Management", "Install", "App Install", null);
            
            let mydir = t.file.applicationDirectory + 'www/assets/data';
            t.log(1,'readCurrentAppJson read from file: ' + mydir + '/data.json');
            t.currentAppData = {"pages":{"home":{"title":"Home"}}};
            resolve(t.currentAppData);

          } else {
            t.currentAppData = {"pages":{"title":"Home","id":"","staticpageurl":"/home","rating":"0"}};
            resolve(t.currentAppData);
            /*            
            let url = '../assets/data/data.json';
            t.log(0,'readCurrentAppJson url=' + url);
            t.http.get(url).toPromise().then((appdata:string)=>{
              t.log(0,'readCurrentAppJson got appdata from url appdata=' + appdata);
              t.currentAppData = appdata;
              resolve(t.currentAppData);               
            }).catch((e2)=>{
              t.log(1,'readCurrentAppJson error 2 ' + JSON.stringify(e2));
              reject(e2);
            });
            */
          }
        });
      }
    })
  } 

  writeCurrentAppJson(data) {
    if (data) {
      this.currentAppData = data;
      let sdata = JSON.stringify(data);
      this.log(1,"writeCurrentAppJson data.length=" + sdata.length + " this.currentAppData=" + this.abbreviate(sdata,40));
      this.setSecureKey('appdata', sdata);
    }
  } 
  
  // filter (search) pages by title and summary
  filterPages(searchTerm) {
    let items = [];
    let currentAppData = this.currentAppData;
    this.log(0,'filterPages searchTerm=' + searchTerm);
 
    if (searchTerm) {
      for (let pageid in currentAppData["pages"]) {
        searchTerm = searchTerm.toLowerCase();
        if (currentAppData["pages"].hasOwnProperty(pageid)) {
          this.log(0,'filterPages pageid=' + pageid + ' displayoptions=' + currentAppData["pages"][pageid]["displayoptions"]);
          if (currentAppData["pages"][pageid]["displayoptions"] && currentAppData["pages"][pageid]["displayoptions"].indexOf("displayinsearch") > -1 &&
            (currentAppData["pages"][pageid]["title"].toLowerCase().indexOf(searchTerm) > -1 || 
            currentAppData["pages"][pageid]["summary"].toLowerCase().indexOf(searchTerm) > -1 )) {
            items.push(currentAppData["pages"][pageid]);
          }
        }
      }
    }
    this.log(1,'filterPages returned items.length=' + items.length);
    return items;  
  }

  // filter contacts  by ?? title and summary - that should be updated when contact structure is clear
  filterContacts(searchTerm) {
    let items = [];
    let currentAppData = this.currentAppData;
    this.log(0,'filterContacts searchTerm=' + searchTerm);
 
    if (searchTerm) {
      for (let pageid in currentAppData["pages"]) {
        searchTerm = searchTerm.toLowerCase();
        if (currentAppData["pages"].hasOwnProperty(pageid)) {
          this.log(0,'filterContacts pageid=' + pageid + ' displayoptions=' + currentAppData["pages"][pageid]["displayoptions"]);
          if (currentAppData["pages"][pageid]["displayoptions"] && currentAppData["pages"][pageid]["displayoptions"].indexOf("displayinsearch") > -1 &&
            currentAppData["pages"][pageid]["section"] && currentAppData["pages"][pageid]["section"] == 'Contacts Database' &&
            (currentAppData["pages"][pageid]["title"].toLowerCase().indexOf(searchTerm) > -1 || 
            currentAppData["pages"][pageid]["summary"].toLowerCase().indexOf(searchTerm) > -1 )) {
            items.push(currentAppData["pages"][pageid]);
          }
        }
      }
    }
    this.log(1,'filterContacts returned items.length=' + items.length);
    return items;  
  }

  getAllPageData(){
    var t = this;
    return this.readCurrentAppJson().then((currentAppData)=>{
      return t.getJsonPropertyByPath(currentAppData, "pages");
    });
  }

  // creates fixed menu for CAP Marketplace App
  getCapMarketplaceMenuPages() {
    let pages = [];
    if (this.userAuthenticated) {
      let page: any = new Object();
      let count = 0;
      if (this.currentUserRole == 'buyer') {
        page.title = 'Dashboard';
        page.staticpageurl = '/home';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Received Quotes';
        page.staticpageurl = '/quote-requests/current';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Past Quotes';
        page.staticpageurl = '/quote-requests/archived';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Current Orders';
        page.staticpageurl = '/orders/current';
        page.id = count++;
        pages.push(page);        
        page = new Object();
        page.title = 'Past Orders';
        page.staticpageurl = '/orders/archived';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Questions & Answers';
        page.staticpageurl = '/information-requests';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Notifications';
        page.staticpageurl = '/notifications-inbox';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Ratings & Reviews';
        page.staticpageurl = '/reviews/' + this.currentUserData.CompanyId;
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Account & Settings';
        page.staticpageurl = '/settings';
        page.id = count++;
        pages.push(page);
      } else if (this.currentUserRole == 'seller') {
        page.title = 'Dashboard';
        page.staticpageurl = '/home';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Current Requests';
        page.staticpageurl = '/quote-requests/current';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Active Bids';
        page.staticpageurl = '/quotes/current';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Past Bids';
        page.staticpageurl = '/quotes/archived';
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Current Orders';
        page.staticpageurl = '/orders/current';
        page.id = count++;
        pages.push(page);        
        page = new Object();
        page.title = 'Past Orders';
        page.staticpageurl = '/orders/archived';
        page.id = count++;
        pages.push(page);   
        page = new Object();
        page.title = 'Questions & Answers';
        page.staticpageurl = '/information-requests';
        page.id = count++;
        pages.push(page);  
        page = new Object();
        page.title = 'Notifications';
        page.staticpageurl = '/notifications-inbox';
        page.id = count++;
        pages.push(page);  
        // page = new Object();
        // page.title = 'Archived Requests';
        // page.staticpageurl = '/quote-requests/archived';
        // page.id = count++;
        // pages.push(page);
        page = new Object();
        page.title = 'Ratings & Reviews';
        page.staticpageurl = '/reviews/' + this.currentUserData.CompanyId;
        page.id = count++;
        pages.push(page);
        page = new Object();
        page.title = 'Account & Settings';
        page.staticpageurl = '/settings';
        page.id = count++;
        pages.push(page);
      }
    }
    this.log(1,'getCapMarketplaceMenuPages userAuthenticated=' + this.userAuthenticated + ' currentUserRole=' + this.currentUserRole + ' pages.length=' + pages.length);
    return pages;
  }

  getMenuPages(){
    var t = this;
    return this.readCurrentAppJson().then((currentAppData)=>{
      let pages = [];
        if (currentAppData["pages"]) {
        t.log(1,'getMenuPages length=' + currentAppData["pages"].length);
        for (let pageid in currentAppData["pages"]) {
          let p = currentAppData["pages"][pageid];
          t.log(0,'getMenuPages pageid=' + pageid + ' p.title=' + p.title + ' p.displayoptions=' + p.displayoptions);
          if (p.displayoptions && p.displayoptions.indexOf('displayinmenu') > -1) {
            let pageTitle = p.title;
            if (!pageTitle || pageTitle == '-') {
              pageTitle = p.label;
            }
            t.log(0,'getMenuPages adding pageTitle=' + pageTitle + ' p.id=' + p.id + ' p.staticpageurl=' + p.staticpageurl);
            pages.push({title:pageTitle,id:p.id,staticpageurl:p.staticpageurl,rating:p.rating});
          }
        }
        pages.sort(function(a, b) { 
          t.log(0,'pages sort: a.rating=' + a.rating + ' a.title=' + a.title + ' b.rating=' + b.rating + ' b.title=' + b.title); 
          let s: number = Number(a.rating) - Number(b.rating);
          if (s == 0) {
            s = a.title.charCodeAt(0) - b.title.charCodeAt(0);
          }
          return s;
        })     
      }  
      return pages;
    });
  }

  // get current page data from application JSON
  getPageData(pageUrl) {
    var t = this;
    return this.readCurrentAppJson().then((currentAppData)=>{
      t.log(0,'getPageData read from currentAppData pageUrl=' + pageUrl);
      //t.log(1,'getPageData read from currentAppData=' + JSON.stringify(currentAppData)); 
      t.log(0,'getPageData read from currentAppData page=' + JSON.stringify(t.getJsonPropertyByPath(currentAppData, pageUrl))); 
      // GR added parse/stringify 20181012 to avoid possibility of circular data references
      let page: any = t.getJsonPropertyByPath(currentAppData, pageUrl);      
      if (typeof page == 'undefined' || !page) {
        page = {'title': 'Page not found'};
      } else if (page.id) {
        //page = JSON.parse(JSON.stringify(t.getJsonPropertyByPath(currentAppData, pageUrl)));
        let currentpage = page.id;
        let parent = page.parentid;
        t.log(0,'getPageData currentpage=' + currentpage + ' parent=' + parent + ' displaysubpages=' + page.displaysubpages);
        let children = [];
        let siblings = [];
        if (page.displaysubpages != "no") {
          for (let pageid in currentAppData["pages"]) {
            if (currentAppData["pages"].hasOwnProperty(pageid)) {
              let cp = currentAppData["pages"][pageid];
              t.log(0,'getPageData pageid=' + pageid + ' parentid=' + cp["parentid"]);
              if (cp["parentid"] == currentpage) {
                children.push(cp);
              } else if (cp["parentid"] == parent) {
                  siblings.push({id:pageid,label:cp['label'],statticpageurl:cp['staticpageurl'],rating:cp['rating'],title:cp['title'],parentid:cp['parentid']});
              }
            }
          }
        }
        t.log(0,'getPageData currentpage=' + currentpage + ' siblings.length=' + siblings.length);
        page.children = children;
        // GR added 2018-07-17
        if (siblings) {
          siblings.sort(function(a, b) { 
            t.log(0,'siblings sort: a.rating=' + a.rating + ' a.title=' + a.title + ' b.rating=' + b.rating + ' b.title=' + b.title); 
            let s: number = Number(a.rating) - Number(b.rating);
            if (s == 0) {
              s = a.label.charCodeAt(0) - b.label.charCodeAt(0);
            }
            return s;
          }) 
        }        
        page.siblings = siblings;
        if (currentAppData["pages"][parent]) {
          page.parentSection = currentAppData["pages"][parent]["section"];
        } else {
          page.parentSection = '';
        }
      }
      //t.log(0,'getPageData page=' + this.abbreviate(JSON.stringify(page),40));
      return page;    
    });
  } 

  // get array of curent user's roster data
  getRosterData() {
    return new Promise<object>((resolve, reject) => {
      if (this.currentRosterData) {
        this.currentRosterData = this.cleanRosterData(this.currentRosterData);
        this.log(0,'getRosterData got rosterdata from memory rosterdata=' +  JSON.stringify(this.currentRosterData));
        resolve(this.currentRosterData);
      } else {
        this.getSecureKey('rosterdata').then((rosterdata:string)=>{
          let needToStoreKeys = true;
          this.log(0,'getRosterData got rosterdata from secure key rosterdata=' + rosterdata);
          if (!rosterdata) {
            this.log(2,'*** getRosterData EMPTY rosterdata from secure key');
          }
          this.currentRosterData = JSON.parse(rosterdata);
          this.currentRosterData = this.cleanRosterData(this.currentRosterData);
          resolve(this.currentRosterData);
        }).catch((error)=>{
          this.log(1,'getRosterData can not read rosterdata from secure key. Create empty data ');
          this.currentRosterData = [];
          resolve(this.currentRosterData);
          //reject({'reason':'just a test'});
        });
      }
    })
  }

  cleanRosterData(rosterData) {
    let newData = [];
    if (rosterData && rosterData.length > 0) {
      for (let i=0; i<rosterData.length; i++) {
        if (rosterData[i].id != this.calendarTmpIdVal) {
          newData.push(rosterData[i]);
        }
      }
    }
    this.log(0,'cleanRosterData length=' + newData.length);
    return newData;
  }   

  setSecureKey(key, value) {
    if (key == 'userpin' && value) {
      // after user set up PIN we want to authenticate him automatically
      this._authenticated = true;
      this.lastAccessed = new Date();
    }    
    this.log(1,'setSecureKey: 1 this.appType=' + this.appType + ' key=' + key + ' cryptokey=' + this.abbreviate(this.currentCryptoKey,6));
    if (this.appType == 'public') {
      // we do not encrypt data for public apps
      this.setKey(key,value);
    } else {
      if (this.currentCryptoKey) {
        this.setKeyEncrypted(key, value, this.currentCryptoKey);
      } else {
        this.log(1,'settings setSecureKey - no currentCryptoKey defined. We should not be here! userToken=' + this.userToken);
        this.getSecureStorage().then((secstor: Storage)=>{       
          secstor.get('cryptokey').then(
            (cryptokey)=>{
              this.log(1,'settings 2 setSecureKey key=' + key + ' cryptokey=' + this.abbreviate(cryptokey,6));
              if (cryptokey != null) {
                this.setKeyEncrypted(key, value, cryptokey);
              } 
            }).catch((error)=>{
              this.log(2,'setSecureKey: can not save key beccause cryptokey is not defined');
            });
        });
      }
    }
  }

  // saving key/value to local storage. Not encrypted version of setKeyEncrypted
  public setKey(key, value) {
    this.log(0,'=== setKey key=' + key);
    //this.storage.ready().then(() => {
      this.storage.set(key, value).then(() => {
        this.log(1,'setKey: key ' + key + ' saved');
      }).catch((error) => {
        this.log(2,'setKey error saving key: ' + JSON.stringify(error));
      });
    //});     
  }

  // reading value of given key from local storage. Not encrypted version of getSecureKey
  public getKey(key) {
    let t = this;
    return new Promise(
      function (resolve, reject) {    
        t.log(0,'=== getKey key=' + key);
        //t.storage.ready().then(() => {
          t.storage.get(key).then((value:string) => {
            t.log(1,'getKey: key ' + key + ' read value=' + value);
            // getSecureKey fails on not existing key. Do the same here
            if (value == null) {
              reject(new Error('getKey: ' + key + ' key not found'));
            } else {
              resolve(value);
            }
          }).catch((error) => {
            t.log(2,'getKey error reading key: ' + JSON.stringify(error));
            reject(error);
          });
        //}); 
    })    
  }

  // to check if secure storage is supported on given divice. It is not supported on android devices version 10 and up


  // to read deviceid from storage (it is supposed to be saved there) and use it to get encryption key from the server.
  // it is alternative to read that value from secure storage
  getKeyEncryptedUsingServer(key) {
    let t = this;
    return new Promise(
      function (resolve, reject) {  
        t.getKey('plainappkeys').then((value:string)=> {
          if (value) {
            let parts = value.split(';');     
            t.userToken = parts[0];
            t.deviceId = parts[1];
            t.log(1,'getKeyEncryptedUsingServer deviceid=' + t.deviceId);
            t.executeCommand('getckey', "").subscribe((result: any) => { 
              if (result && result.Success) {
                t.currentCryptoKey = result.Message;
                t.log(1,'getKeyEncryptedUsingServer server result=' + t.currentCryptoKey);        
                return t.getKeyEncrypted(key, t.currentCryptoKey).then((keyVal)=>{
                  t.log(1,'getKeyEncryptedUsingServer keyVal=' + keyVal);
                  resolve(keyVal);
                }).catch((error)=>{
                  t.log(2,'getKeyEncryptedUsingServer can not get read key');
                  reject('Can not read data. You may need to re-authenticate the App');              
                });       
              } else {
                t.log(2,'getKeyEncryptedUsingServer can not get ckey from the server ' + result.Message);
                reject('Can not communicate with the server to check authentication. Please check network connection and try to restart or reauthenticate the App later.');            
              }
            },  error =>  {
              t.log(2,'getKeyEncryptedUsingServer server error ' + error);
              reject('Can not communicate with the server to check authentication. Please check network connection and try to restart or reauthenticate the App later');            
            });		
          } else {
            t.log(2,'getKeyEncryptedUsingServer no value for appkeys is stored');
            reject('Can not read data. You may need to reauthenticate the App.');		
          }
        }).catch((error)=>{
          t.log(2,"getKeyEncryptedUsingServer: getKey error" + error.message);
          reject('No plainappkeys data');
        });
    })
  }

  private setKeyEncrypted(key, value, cryptokey) {
    this.log(1,'setKeyEncrypted before encrypt value=' + this.abbreviate(value,5) + ' cryptokey=' + this.abbreviate(cryptokey,5));
    let encryptedValue = CryptoJS.AES.encrypt(value, cryptokey).toString();
    this.log(1,'=== setKeyEncrypted key=' + key + ' encryptedValue=' + this.abbreviate(encryptedValue,10));
    //this.storage.ready().then(() => {
      this.storage.set(key, encryptedValue).then(() => {
        this.log(1,'setKeyEncrypted: key ' + key + ' saved');
      }).catch((error) => {
        this.log(2,'setKeyEncrypted error saving key: ' + JSON.stringify(error));
      });
    //});      
  }

  // read value for given key from the storage and decrypt it.
  // It should be exact equivalent of getSecureKey1 but without use of async/await
  // in fact using async/await makes handling exceptions (catch) more complicated 
  public getSecureKey(key) {
    let t = this;
    t.log(1,'settings getSecureKey t.appType=' + t.appType + ' key=' + key + ' t.currentCryptoKey=' + t.abbreviate(t.currentCryptoKey,5)); 
    if (t.appType == 'public') {
      // we do not encrypt data for public apps
      return t.getKey(key);
    } else if (t.currentCryptoKey) {
      return t.getKeyEncrypted(key, t.currentCryptoKey);
    } else {
      return new Promise(
        function (resolve, reject) {      
          if (!t.initialiseSecureStorageError) {
              // "normal call to secure storage"
              t.log(1,'settings getSecureKey getting cryptokey ss=' + t.ss + ' key=' + key);
              let ssPromise = t.getSecureStorage(key);
              t.log(1,'settings getSecureKey ssPromise=' + ssPromise + ' key=' + key);
              if (ssPromise) {
                resolve(ssPromise.then((secstor: any)=>{
                  t.log(1,'settings getSecureKey secstor=' + secstor + ' key=' + key);
                  let ckPromise = secstor.get('cryptokey');
                  t.log(1,'settings getSecureKey ckPromise=' + ckPromise);
                  if (ckPromise) {
                    return ckPromise.then((cryptokey)=>{
                        t.log(1,'settings getSecureKey cryptokey=' + t.abbreviate(cryptokey,5) + ' key=' + key);
                        t.currentCryptoKey = cryptokey;
                        return t.getKeyEncrypted(key, cryptokey);  
                    }).catch((error)=>{
                      t.log(1,'*** getSecureKey secstor.get ERROR ' + error.message);
                    reject('Can not read data from secure storage. Possibly it is caused by screen lock changes on your device. You may need to reauthenticate the App.');
                    });
                  } else {
                  reject('Can not read data from secure storage. Possibly it is caused by screen lock changes on your device. You may need to reauthenticate the App.');
                  }
                }).catch((error)=>{
                t.log(1,'*** getSecureKey getSecureStorage key=' + key + ' ERROR ' + error.message + " initialiseSecureStorageError=" + t.initialiseSecureStorageError);
                // That is likely to be a first call after restart. We may want to read cryptokey from the server here
                //reject(error);
                return t.getKeyEncryptedUsingServer(key).then((keyVal)=>{
                  t.log(1,'getSecureKey 1 after getKeyEncryptedUsingServer key=' + key + ' value=' + keyVal);
                  resolve(keyVal);
                }).catch((error)=>{
                  reject(error);
                })
                }));
              } else {
              t.log(1,'*** getSecureKey getSecureStorage returns null for key=' + key);
              return t.getKeyEncryptedUsingServer(key).then((keyVal)=>{
                t.log(1,'getSecureKey 2 after getKeyEncryptedUsingServer key=' + key + ' value=' + keyVal);
                resolve(keyVal);
              }).catch((error)=>{
                reject(error);
              })     
              }
            } else {
            return t.getKeyEncryptedUsingServer(key).then((keyVal)=>{
              t.log(1,'getSecureKey 3 after getKeyEncryptedUsingServer key=' + key + ' value=' + keyVal);
              resolve(keyVal);
            }).catch((error)=>{
              reject(error);
            })
            }
        });
    }
  }

  // internal function to read value for given key from the storage and decrypt it with 
  // provided cryptokey
  private getKeyEncrypted(key, cryptokey) {
    var t = this;
    return new Promise(
      function (resolve, reject) {
        //t.storage.ready().then(() => {
          t.log(1,'settings getKeyEncrypted storage ready driver=' + t.storage.driver + ' key=' + key);
          t.storage.get(key).then((value) => {
            t.log(1,'getKeyEncrypted got value from storage');
            if (cryptokey == null || !cryptokey) {
              reject('No cryptokey defined');
            } else if (value == null) {
              t.log(1,'getKeyEncrypted No data for given key ' + key + ' is stored');
              reject('getKeyEncrypted: No data for given key ' + key + ' is stored');
            } else {
              t.log(1,'getKeyEncrypted before decrypt value=' + t.abbreviate(value,5) + ' cryptokey=' + t.abbreviate(cryptokey,5));
              let decrypted = CryptoJS.AES.decrypt(value, cryptokey).toString(CryptoJS.enc.Utf8);
              t.log(1,'getKeyEncrypted value decrypted for key=' + key);
              if (decrypted) {
                resolve(decrypted);
              } else {
                t.log(1,'getKeyEncryptedCan not decrypt value');
                reject('Can not decrypt value');
              }
            }
          }).catch((error)=>{
            t.log(1,'getKeyEncrypted Error getting data from storage: ' + error);
            reject('getKeyEncrypted Error getting data from storage: ' + error)
          });
        //});
      }
    );
  }       

  checkServerForNotifications() {
    this.log(1,'checkServerForNotifications');
    this.notificationCheck.isRunning = true;
    this.executeCommand('getdevicenotificationjson', "")
    .subscribe((result: any) => { 
        this.lastNotificationChecked = new Date();
        this.log(1,'getdevicenotificationjson http post result=' + result + " o=" + JSON.stringify(result));
        //this.notifications = this.dedupArrayById(this.notifications);          
        //this.log(1,'getdevicenotificationjson notifications.length=' + result.notifications.length);
        if (result.notifications && result.notifications.length > 0) {
          this.trackMatomoEvent("Interaction", "Notification", result.notifications.length + " new notification(s) received from the server", result.notifications.length);
          // somehow we managed to get the same notification twice so below we compare ids to avoid it  
          for(var i=0;i<result.notifications.length;i++) {
            if (this.notifications.length == 0 || this.notifications.some(e => e.id !== result.notifications[i].id)) {
              this.notifications.push(result.notifications[i]);
            }
          }
          //this.notifications = result.notifications.concat(this.notifications);
          this.saveNotifications();
          this.notificationsNewCount = this.getNewNotificationsCount();
        }
        this.log(1,'getdevicenotificationjson this.notifications.length=' + this.notifications.length + " notificationsNewCount=" + this.notificationsNewCount);        
        this.notificationCheck.isRunning = false;
        // GR We need to "clear up" saved JWT keys periodically in case if they will be updated on the server.  
        // Do it here when notification are read from the server;
        this._jwtsk = [];
    },  error =>  {
      this.log(2,'getdevicenotificationjson server error ' + error);
      this.notificationCheck.isRunning = false;
    });   
  }

  getNewNotificationsCount() {
    let count = 0;
    if (this.notifications) {
      for (let i=0; i<this.notifications.length; i++) {
        if (this.notifications[i].status == "new") {
          count++;
        }
      }
    }
    return count;
  }

  getHomePageNotifications() {
    let hpn = [];
    let count = 0;
    if (this.notifications) {
      for (let i=0; i<this.notifications.length; i++) {
        if (this.notifications[i].status == "new") {
          hpn.push(this.notifications[i]);
          count++;
        } else if (this.notifications[i].status == "read" && this.notifications[i].homepagedisplayoption == "Visible Until Dismissed") {
          hpn.push(this.notifications[i]);
        }
      }
      this.log(1,"getHomePageNotifications:notifications.length=" + this.notifications.length + " hpn.length=" + hpn.length);
      hpn.sort(this.compareNotifications);
    }
    return {"new": count, "notifications": hpn};
  }

  getInboxNotifications() {
    let n = [];
    let ncount = 0;
    let rcount = 0;
    if (this.notifications) {
      for (let i=0; i<this.notifications.length; i++) {
        if (this.notifications[i].status == "new") {
          n.push(this.notifications[i]);
          ncount++;
        } else if (this.notifications[i].status == "read" || this.notifications[i].status == "dismissed") {
          n.push(this.notifications[i]);
          rcount++;
        }
      }
      n.sort(this.compareNotifications);
    }
    return {"new": ncount, "read": rcount, "notifications": n};
  }

  getArchiveNotifications() {
    let n = [];
    let ncount = 0;
    let acount = 0;
    if (this.notifications) {
      for (let i=0; i<this.notifications.length; i++) {
        if (this.notifications[i].status == "archived") {
          n.push(this.notifications[i]);
          acount++;
        } else if (this.notifications[i].status == "new") {
          ncount++;
        }
      }
      n.sort(this.compareNotifications);
    }
    return {"new": ncount, "archived": acount, "notifications": n};
  }

  compareNotifications(a, b) {
    let aprefix = "0.";
    let val: number = 0;
    if ((a.status == "new" || a.status == "read") && a.homepagedisplayoption == "Visible Until Dismissed") {
      aprefix = "4.";
    } else if (a.status == "new" && a.homepagedisplayoption != "Visible Until Dismissed") {
      aprefix = "3.";
    } else if (a.status == "dismissed" || (a.status == "read"  && a.homepagedisplayoption != "Visible Until Dismissed")) {
      aprefix = "2.";
    } else if (a.status == "archived") {
      aprefix = "1.";
    }
    let bprefix = "0.";
    if ((b.status == "new" || b.status == "read") && b.homepagedisplayoption == "Visible Until Dismissed") {
      bprefix = "4.";
    } else if (b.status == "new" && b.homepagedisplayoption != "Visible Until Dismissed") {
      bprefix = "3.";
    } else if (b.status == "dismissed" || (b.status == "read"  && b.homepagedisplayoption != "Visible Until Dismissed")) {
      bprefix = "2.";
    } else if (b.status == "archived") {
      bprefix = "1.";
    }    
    if ( (aprefix + a.sent) > (bprefix + b.sent) ) {
      val = -1;
    } else if ((aprefix + a.sent) < (bprefix + b.sent)) {
      val = 1;
    } 
    //console.log("compareNotifications a=" + (aprefix + a.sent) + " b=" + (bprefix + b.sent) + " val=" + val);   
    return val;
  }

  getNotificationById(id) {
    let notification = {};
    if (this.notifications) {
      for (let i=0; i<this.notifications.length; i++) {
        if (this.notifications[i].id == id) {
          notification = this.notifications[i];
          break;
        }
      }    
    }
    return notification;
  }

  setNotificationStatus(id, status) {
    let statusUpdated = false;
    let title = '';
    if (this.notifications) {
      for (let i=0; i<this.notifications.length; i++) {
        if (this.notifications[i].id == id) {
          if (this.notifications[i].status != status) {
            this.notifications[i].status = status;
            title = this.notifications[i].title;
            statusUpdated = true;
          }
        }
      }
      this.trackMatomoEvent("Interaction", "Notification", title + " status changed to " + status, null);
      this.log(1,"setNotificationStatus: id=" + id + " ststus=" + status + " statusUpdated=" + statusUpdated);
      if (statusUpdated) {
        this.saveNotifications();
      }
    }
  }

  saveNotifications() {
    this.setSecureKey('notifications', JSON.stringify(this.notifications));
  }

  // get notifications either from local variable (memory) or from storage
  getNotifications() {
    let t = this;
    return new Promise<object>((resolve, reject) => {  
      if (t.notifications && t.notifications.length > 0) {
        t.log(1,'getNotifications read notifications from memory');
        resolve(t.notifications);
      } else {
        t.getSecureKey('notifications').then((value:string)=> {
          t.log(1,'getNotifications read notifications from storage');
          t.notifications = JSON.parse(value);
          resolve(t.notifications);
        }).catch((error)=>{
          t.log(1,'getNotifications no notifications found in storage');
          t.notifications = [];
          resolve(t.notifications);
        });
      }
    })
  }	

  getShiftName(shifttype: string){
    let name = '';
    let index = this.rosterShiftTypes.findIndex(x => x.value==shifttype);
    this.log(0,'getShiftName shifttype=' + shifttype + ' index=' + index);
    if (index > -1) {
      name = this.rosterShiftTypes[index].name;
    }
    return name;
  }
  
  // get JSON property by path like 'data/body'
  getJsonPropertyByPath(jsonObj, path) {
    let property = null;
    this.log(0,"getJsonPropertyByPath path=" + path);
    if (jsonObj) {
      if (path && path.substr(0,1) == '/') {
        path = path.substr(1);
      }
      if (path) {
        let parts = path.split('/');
        this.log(0,"getJsonPropertyByPath parts.length=" + parts.length);
        if (parts.length == 1){
          property = jsonObj[parts[0]];
        } else {
          property = this.getJsonPropertyByPath(jsonObj[parts[0]], parts.slice(1).join('/'));
        }
        this.log(0,"getJsonPropertyByPath parts.length=" + parts.length + " path=" + path + " property=" + this.abbreviate(JSON.stringify(property),100));
      } else {
        property = jsonObj;
      }
    }
    //this.log(0,"getJsonPropertyByPath *** path=" + path + " property=" + this.abbreviate(JSON.stringify(property),50));
    return property;
  }

  // get integer number of minutes between 2 date objects
  public timeDiffInMinutes(endDate, startDate)  {
    let diff = 0;
    this.log(0,'timeDiffInMinutes: endDate=' + endDate + ' startDate=' + startDate);
    if (endDate instanceof Date && startDate instanceof Date) {
      diff = (endDate.getTime() - startDate.getTime()) / 1000;
      this.log(0,'timeDiffInMinutes: a seconds diff=' + diff);
      diff = Math.abs(Math.round(diff/60));
    } else if (!isNaN(startDate) && !isNaN(endDate)) {
      diff = (Number(endDate) - Number(startDate)) / 1000;
      this.log(0,'timeDiffInMinutes: b seconds diff=' + diff);
      diff = Math.abs(Math.round(diff/60));
    } else {
      this.log(0,'*** timeDiffInMinutes: ERROR endDate or/and startDate is not date');      
    }
    this.log(0,'timeDiffInMinutes: diff=' + diff); 
    return diff;
  } 

  // set various settings variables from appsettings
  setConfigVariables(appsettings) {
    let s = this;

    s.appType = appsettings.appType;
    if (s.appType == 'public') {
      s.userToken = '--';
    }
    if (appsettings.appSiteHost && appsettings.appSiteHost.substring(0,4) != "http") {
      //protocol should be added
      appsettings.appSiteHost = "https://" + appsettings.appSiteHost;
    }
    s.appSiteHost = appsettings.appSiteHost;
    s.log(1,'setConfigVariables appType=' + s.appType + ' appSiteHost=' + s.appSiteHost + ' userToken=' + s.userToken + ' appsettings.companyName=' + appsettings.companyName);

    if (appsettings.piwikServerUrl) {
      if (appsettings.piwikServerUrl.substring(0,4) != "http") {
        appsettings.piwikServerUrl = "https://" + appsettings.piwikServerUrl;
      }
      if (appsettings.piwikServerUrl.substr(-1) != "/") {
        // we assume later that matomoUrl ends with "/"
        appsettings.piwikServerUrl = appsettings.piwikServerUrl + "/";
      }
    }
    let matomoUrl = appsettings.piwikServerUrl;

    if (s.matomoUrl != matomoUrl || s.matomoSiteId != appsettings.piwikSiteId) {
      this._matomoInitialised = false;
    }    
    s.matomoUrl = matomoUrl;
    s.matomoSiteId = appsettings.piwikSiteId;
    s.trackMatomoAnalytics = s.getBoolean(appsettings.piwikEnabled);
    s.trackFirebaseAnalytics = s.getBoolean(appsettings.firebaseAnalyticsEnabled);
    if (appsettings.sessionTimeInMinutes) {
      s.sessionTimeInMinutes = 1*appsettings.sessionTimeInMinutes;
    }
    if (appsettings.passwordSessionTimeInMinutes) {
      s.passwordSessionTimeInMinutes = 1*appsettings.passwordSessionTimeInMinutes;
    }    
    s.log(1,'setConfigVariables 1a appsettings.passwordAuthenticationInBrowserEnabled=' + appsettings.passwordAuthenticationInBrowserEnabled + ' s.passwordAuthenticationInBrowserEnabled=' + s.passwordAuthenticationInBrowserEnabled);

    if (appsettings.passwordAuthenticationInBrowserEnabled == "true") {
      s.passwordAuthenticationInBrowserEnabled = true;
      if (!s.platformIsCordova) {
        s.passwordAuthentication = true;
        s._authorised = true; // - do not require email authorisation
      }
    } else if (appsettings.passwordAuthenticationInBrowserEnabled == "false" && s.passwordAuthenticationInBrowserEnabled) {
      s.passwordAuthenticationInBrowserEnabled = false;
      if (!s.platformIsCordova) {
        s.passwordAuthentication = false;
        s._authorised = false; // - require email authorisation
      }
    }
    s.log(2,'setConfigVariables 1b s.passwordAuthentication=' + s.passwordAuthentication + ' s.platformIsCordova=' + s.platformIsCordova + ' s.passwordAuthenticationInBrowserEnabled=' + s.passwordAuthenticationInBrowserEnabled);

    if (appsettings.errorLogMaxEntriesToKeep) {
      s.errorLogMaxEntriesToKeep = 1*appsettings.errorLogMaxEntriesToKeep;
    }
    if (appsettings.errorLogMinLogLevelToKeep) {
      s.errorLogMinLogLevelToKeep = 1*appsettings.errorLogMinLogLevelToKeep;
    }
    if (appsettings.checkForUpdatesTimeInMinutes) {
      s.checkForUpdatesTimeInMinutes = 1*appsettings.checkForUpdatesTimeInMinutes;
    }
    if (appsettings.homePageNotificationsLimit) {
      s.homePageNotificationsLimit = 1*appsettings.homePageNotificationsLimit;
    }
    if (appsettings.logDisplayLevel) {
      s.logDisplayLevel = 1*appsettings.logDisplayLevel;
    }
    if (appsettings.companyName) {
      s.companyName = appsettings.companyName;
    }
  }

  // after switching from cordova-ios v 6 our local images are not loaded
  normalizeURL2(entry) {
    let newUrl = entry.toURL();
    if (this.platform.is('ios')) {
      // GR. Does below always work? app & localhost below must correspond to scheme and hostname preferences defined in config.xml
      // 2021-07-14 GR it seems that using custom scheme breaks app working in android. When I removed <preference name="scheme" value="app" />
      // from config.xml app build worked but not with it (: May be will try to add it to ios section?
      //newUrl = newUrl.replace("file://","app://localhost/_app_file_");
      newUrl = this.webView.convertFileSrc(newUrl);
      //newUrl = window.WkWebView.convertFilePath(url);
    } else if (this.platform.is('android')) {
      newUrl = this.webView.convertFileSrc(newUrl); 
    }
    return newUrl;
  }

  // this function is supposed to grab image from given full URL, save it to the local file and return
  // it's location in "local URL format" e.g. /assets/imgs/some_image.jpg"
  getExternalImage(host,imageUrl) {
    var imageRelativeUrl = imageUrl;
    if (imageUrl && imageUrl.length > 10 && imageUrl.substring(0,4) == "http" && imageUrl.indexOf("//") > -1) {
      var hostItems = imageUrl.split("//");
      var urlItems = hostItems[1].split("/");
      host = hostItems + "//" + urlItems[0];
      imageRelativeUrl = hostItems[1].replace(urlItems[0] + "/","");
    }	
    this.log(0,'getExternalImage host=' + host + ' imageUrl=' + imageUrl + ' imageRelativeUrl=' + imageRelativeUrl + 
      ' this.platformIsCordova=' + this.platformIsCordova);
    var url = host + imageRelativeUrl;
    var t = this;
    return new Promise(function(resolve){
      if (imageUrl && imageUrl.length > 10 && imageUrl.substring(0,5) == "data:") {
        t.log(1,'getExternalImage: inline image');   
        // GR we should check for existance of image at t.sourceHost + imageUrl and substitute it with
        // spacer image if it doesn't exist but this is just for browser testing so it can wait
        resolve({"old": imageUrl, "new": imageUrl});        
      } else if (imageRelativeUrl && t.platformIsCordova) {
        let urlItems = imageRelativeUrl.split("/");
        var fileName = urlItems[urlItems.length - 1];

        var prefix = imageRelativeUrl.replace(fileName,"").replace("/images/","").replace(/\//g,"_");
        if (prefix) {
          prefix += '_';
          fileName = prefix + fileName;
        }
        t.log(0,'getExternalImage prefix=' + prefix);

        var fullDirectory = t.file.dataDirectory; //t.file.applicationDirectory + 'www/assets/imgs';

        t.log(0,'fullDirectory=' + fullDirectory + ' fileName=' + fileName);
        t.file.resolveLocalFilesystemUrl(fullDirectory).then((entry: DirectoryEntry) => {
          let dirUrl = t.normalizeURL2(entry);
          t.log(1,'resolveLocalFileSystemURL image folder: ' + dirUrl);

          entry.getDirectory('images', { create: true }, function (dirEntry) {
            //t.createDirectoryPath(entry, directory, function(){
            t.log(1,'checkFile dir=' + fullDirectory + 'images/' + ' url=' + dirUrl + ' file=' + fileName);
            t.file.resolveLocalFilesystemUrl(fullDirectory + 'images/' + fileName).then((fe: DirectoryEntry)=>{
              t.log(1,'local image file already exists url=' + t.normalizeURL2(fe));
              resolve({"old": imageUrl, "new": t.normalizeURL2(fe)});

            }).catch((error)=>{
              // for some reason we are coming here when file doesn't exist
              t.log(0,'checkFile error: ' + JSON.stringify(error) + ' file=' + fileName);
              t.log(1,'start getBinaryFile 2 url=' + url);
              //
              t.getBinaryFile(url).subscribe(function(response){
                t.log(1,'checkFile getBinaryFile download complete 2 url=' + url + ' before write to fullDirectory=' + fullDirectory);
                // response is arrayBuffer
                var blob = new Blob([response], { type: 'image/' + fileName.substr(fileName.length - 5) });
                t.log(0,"blob=" + blob);
                //t.saveFile(dirEntry, blob, fileName,t);
  
                //
                t.file.writeFile(fullDirectory + 'images/', fileName, blob, { replace: true }).then((entry)=>{
                  t.log(1,'getExternalImage save downloaded file complete: ' + entry.toURL() + ' normalize=' + t.normalizeURL2(entry));             
                  resolve({"old": imageUrl, "new": t.normalizeURL2(entry)});                
                }).catch((error)=>{
                  t.log(2,'getExternalImage save downloaded file error: ' + JSON.stringify(error) + ' url=' + url);
                });
                //
              }, function(error) {
                t.log(2,'getExternalImage download error: ' + JSON.stringify(error) + ' url=' + url);
                // if we are here then external image doesn't exist or we can't get it. Use spacer image instead:
                let spacer = t.file.applicationDirectory + 'www/assets/imgs/spacer.gif';
                t.file.resolveLocalFilesystemUrl(spacer).then((se: DirectoryEntry) => {
                  // add existing spacer instead of missing image
                  resolve({"old": imageUrl, "new": t.normalizeURL2(se)});
                }).catch((error)=>{
                  t.log(2,'can not find spacer');
                  resolve({"old": imageUrl, "new": imageUrl}); 
                });
              });  
              //             
            });
          }, function(error) {
            t.log(2,'Error creating directory ' + JSON.stringify(error));
            resolve({"old": imageUrl, "new": t.sourceHost + imageUrl});
          });
        }).catch((error)=>{
          console.log('resolveLocalFilesystemUrl error resolving directory=' + fullDirectory + ' error: ' + error);
          resolve({"old": imageUrl, "new": t.sourceHost + imageUrl});
        });
      } else {
        t.log(1,'work around not cordova app');   
        // GR we should check for existance of image at t.sourceHost + imageUrl and substitute it with
        // spacer image if it doesn't exist but this is just for browser testing so it can wait
        resolve({"old": imageUrl, "new": t.sourceHost + imageUrl});
      }
    }); 
  }

  // save all modules data which came from the server and update local variables
  setModulesData(modulesdata) {
    if (!this.coremodulesdata) {
      // this should never happen!
      this.coremodulesdata = {};
    }
    if (!this.othermodulesdata) {
      this.othermodulesdata = {};
    }
    var callNow = true;
    if (modulesdata && modulesdata.appsettings && modulesdata.appsettings.appType) {
      this.log(1,'setModulesData companyLogo=' + modulesdata.appsettings.companyLogo + ' appsettings=' + this.appsettings + ' appVersionChanged=' + this.currentUserData.appVersionChanged);
      if (modulesdata.appsettings.companyLogo && (this.currentUserData.appVersionChanged || !this.appsettings || 
        !this.appsettings.companyLogoLocal || modulesdata.appsettings.companyLogo != this.appsettings.companyLogo)) {
        callNow = false;
        this.getExternalImage(this.sourceHost,modulesdata.appsettings.companyLogo).then((result: any) => {
          this.log(1,"setModulesData companyLogo old=" + result.old + " new=" + result.new);
          modulesdata.appsettings.companyLogoLocal = result.new;
          this.appsettings.companyLogoLocal = result.new;
          this.coremodulesdata.appsettings.companyLogoLocal = result.new;
          this.setModulesData2(modulesdata);
        })
      } else if (this.appsettings.companyLogoLocal) {
        modulesdata.appsettings.companyLogoLocal = this.appsettings.companyLogoLocal;
      }  
    
      // update individual config variables
      this.appsettings = modulesdata.appsettings;
      this.coremodulesdata.appsettings = modulesdata.appsettings;
      this.log(1,'setModulesData callNow=' + callNow + ' appsettings=' + JSON.stringify(this.appsettings));
      this.setConfigVariables(modulesdata.appsettings);
    }
    if (callNow) {
      this.setModulesData2(modulesdata);
    }
  }

  setModulesData2(modulesdata) { 
    if (modulesdata && modulesdata.staticpagesmap) {
      // update staticPageMap
      this.staticPageMap = modulesdata.staticpagesmap;
      this.log(1,'setModulesData2 staticPageMap=' + JSON.stringify(this.staticPageMap));
    }    

    let moduleIds = "";
    let sep = "";
    let coreModuleIds = "";
    let csep = "";
    this.log(1,'setModulesData2 modulesdata=' + this.abbreviate(JSON.stringify(modulesdata),100));
    for (let moduleId in modulesdata) {
      this.log(1,'setModulesData2 moduleId=' + moduleId + ' modulesdata[moduleId]=' + this.abbreviate(JSON.stringify(modulesdata[moduleId]),100) + 
          ' LoadBeforeAuthentication=' + this.getBoolean(modulesdata[moduleId].LoadBeforeAuthentication));
      if (this.getBoolean(modulesdata[moduleId].LoadBeforeAuthentication)) {
        this.coremodulesdata[moduleId] = modulesdata[moduleId];
        coreModuleIds += csep + moduleId;
        csep = ",";
      } else {     
        this.othermodulesdata[moduleId] = modulesdata[moduleId];
        moduleIds += sep + moduleId;
        sep = ",";
      }
    }
    this.log(1,'setModulesData2 setting data for coreModuleIds=' + coreModuleIds + ' modules=' + moduleIds);
    if (coreModuleIds) {
      this.setKey('coremodulesdata',JSON.stringify(this.coremodulesdata));
    }
    if (moduleIds) {
      this.setSecureKey('othermodulesdata',JSON.stringify(this.othermodulesdata));
    }
  }

  // check if any modules need to be updated and update them from the server
  updateModulesFromServer(serverModuleslastmodified) {
    let moduleIds = this.findModulesToUpdate(serverModuleslastmodified, this.currentAppData.moduleslastmodified);
    this.log(1,'updateModulesFromServer serverModuleslastmodified=' + JSON.stringify(serverModuleslastmodified) + ' app moduleslastmodified=' + 
    JSON.stringify(this.currentAppData.moduleslastmodified) + " moduleIds=" + moduleIds);
    if (moduleIds) {
      this.getUpdatedModulesDataFromServer(moduleIds);
      if (this.currentAppData.moduleslastmodified) {
        for (let moduleId in serverModuleslastmodified) {
          this.currentAppData.moduleslastmodified[moduleId] = serverModuleslastmodified[moduleId];
        }        
      } else {
        this.currentAppData.moduleslastmodified = {serverModuleslastmodified};
      }
    }
  }

  // returns comma separated list of moduleIds for modules which has new or updated data on the server
  findModulesToUpdate(serverModuleslastmodified, appModuleslastmodified) {
    let moduleIds = "";
    let sep = "";
    if (serverModuleslastmodified) {
      for (let moduleId in serverModuleslastmodified) {
        // lastModified times are stored in yyyymmddHHMMSS format
        let serverLastModified = 1*serverModuleslastmodified[moduleId];
        let appLastModified = 0;
        if (appModuleslastmodified && appModuleslastmodified[moduleId]) {
          appLastModified = 1*appModuleslastmodified[moduleId];
        }
        this.log(1,'findModulesToUpdate moduleId=' + moduleId + ' serverLastModified=' + serverLastModified + ' appLastModified=' + appLastModified + " updateAllModules=" + this.updateAllModules);
        if (this.othermodulesdata) {
          this.log(1,'findModulesToUpdate 2 moduleId=' + moduleId + ' coremodules=' + this.coremodulesdata[moduleId] + ' othermodules=' + this.othermodulesdata[moduleId]);
        } else {
          this.log(1,'findModulesToUpdate 1 moduleId=' + moduleId + ' coremodules=' + this.coremodulesdata[moduleId] + ' this.othermodulesdata=' + this.othermodulesdata);
        }
        //if ((serverLastModified > appLastModified || this.updateAllModules)) {
        if ((serverLastModified > appLastModified || this.updateAllModules) || 
          (!this.coremodulesdata[moduleId] && !this.othermodulesdata) || 
          (!this.coremodulesdata[moduleId] && !this.othermodulesdata[moduleId])) {
            moduleIds += sep + moduleId;
          sep = ",";          
        }
      }
      this.updateAllModules = false;
    }
    this.log(1,'findModulesToUpdate moduleIds=' + moduleIds);
    return moduleIds;
  }
  
  // read updated (or new) data for given app modules from the server
  getUpdatedModulesDataFromServer(moduleIds) {
    let s = this;
    s.log(1,'getUpdatedModulesData moduleIds=' + moduleIds);
    if (moduleIds) {
      let commandParameters = 'm=' + encodeURIComponent(moduleIds) + '&userid=' + s.currentUserData.Userid;
      s.executeCommand('getmodulesdata', commandParameters).subscribe((result: any) => {  
        if (result && result.Success) {
          s.log(1,'getUpdatedModulesData server success result=' + this.abbreviate(JSON.stringify(result.modulesdata),50));        
          s.setModulesData(result.modulesdata);
        } else {
          s.log(1,'getUpdatedModulesData can not get data from the server result=' + JSON.stringify(result));          
        }
      },  error =>  {
        s.log(2,'getUpdatedModulesData server error ' + JSON.stringify(error));
      });
    }
  }

  // get data from "not core" modules either from local variable (memory) or from storage
  // run it after user is authenticated and after reading currentAppData
  getOtherModulesData() {
    let s = this;
    return new Promise<object>((resolve, reject) => {  
      this.currentAppData.moduleslastmodified
      if (s.othermodulesdata && Object.keys(s.othermodulesdata).length > 0) { 
        s.log(1,'getOtherModulesData read othermodulesdata from memory s.othermodulesdata=' + JSON.stringify(s.othermodulesdata));
        resolve(s.othermodulesdata);
      } else {
        s.getSecureKey('othermodulesdata').then((value:string)=> {
          s.log(1,'getOtherModulesData read othermodulesdata from storage');
          resolve(JSON.parse(value));
        }).catch((error)=>{
		      // probably no modules data were stored
          s.log(1,"getOtherModulesData: getKey error " + error);
          resolve({"emptymodule":{}});
          //reject(error);
        });
      }
    })
  }

  // get core data either from local variable or from storage or from the server
  // run on application load
  getCoreModulesData() {
    let s = this;
    return new Promise<object>((resolve, reject) => {  
      if (s.coremodulesdata && s.coremodulesdata.appsettings) {
        s.appsettings = s.coremodulesdata.appsettings;
        resolve(s.coremodulesdata);
      } else {
        s.getKey('coremodulesdata').then((value:string)=> {
          s.coremodulesdata = JSON.parse(value);
          s.appsettings = s.coremodulesdata.appsettings;
          s.setConfigVariables(s.coremodulesdata.appsettings);
          if (s.coremodulesdata && s.coremodulesdata.staticpagesmap) {
            // update staticPageMap
            s.staticPageMap = s.coremodulesdata.staticpagesmap;
          }            
          resolve(s.coremodulesdata);
        }).catch((error)=>{
          s.log(1,'getCoreModulesData no value for coremodulesdata is stored');
            s.executeCommand('getcoremodulesdata', "").subscribe((result: any) => { 
              if (result && result.Success) {
                s.setModulesData(result.modulesdata);
                s.log(1,'getCoreModulesData server result=' + JSON.stringify(result.modulesdata)); 
                s.setConfigVariables(result.modulesdata.appsettings);     
                resolve(result.modulesdata);
              } else {
                s.log(2,'getcoremodulesdata can not get data from the server');
                reject(new Error('getcoremodulesdata can not get data from the server'));            
              }
            },  error =>  {
              s.log(2,'getCoreModulesData server error ' + error);
              reject(error);
            });			
        });
      }
    })
  }

  // get boolean value from the string or use default value (false by default)
  public getBoolean(str: string, defaultValue=false) {
    let bln = defaultValue;
    if (str) {
      str = str.toLowerCase();
      if (defaultValue) {
        if (str == "0" || str == "f" || str == "false" || str == "n" || str == "no") {
          bln = false;
        }
      } else {
        if (str == "1" || str == "t" || str == "true" || str == "y" || str == "yes") {
          bln = true;
        }
      }
    }
    return bln;
  }
  
  // get "pseudo random" string of given length and possibly with characters from provided "alphabet" only
  public getRandomString(stringLength, alphabet='') {
    if (!alphabet) {
      alphabet = '23456789abcdegjkmnpqrstvwxyz';
    }
    let rst = '';
    for (let i = 0; i < stringLength; i++) {
      rst += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }
    return rst;
  }

  // get jwt key from the server
  private getJwtSK(host) {
    let t = this;
    return new Promise<string>((resolve, reject) => {
      if (t._jwtsk[host]) {
        t.log(1,'getJwtSK key already exists');
        resolve(t._jwtsk[host]);        
      } else {
        let commandParameters = 'host=' + host;
        // GR 20200630 server side updates are required!
        t.executeCommand('getjwtkey',commandParameters)
        .subscribe((result: any) => { 
            if (result && result.Success) {
              t._jwtsk[host] = result.jwtkey;
              resolve(t._jwtsk[host]);
            } else {
              t.log(2,'getJwtSK error message=' + result.Message);
              reject(result.Message);
            }
          },  error =>  {
            t.log(2,'getJwtSK error: ' + JSON.stringify(error));
            reject(JSON.stringify(error));
         });
      }
    })  
  }

  // Attention! getJwtToken was updated and base64url was created on 2020-03-11 Updated versions are required for auto login on new site!
  // get hostname from URL
  public getHostname(url) {
    return new URL(url).hostname;
  }

  // if one adds jwt=token to QS in a link to "master" site then he will be authomatically authenticated
  public getJwtToken(url='',redirectAfterLogin='self') {
    if (this.appType == 'public') {
      this.log(1,'getJwtToken do not produce jwt token for the public app');
      return Promise.reject(new Error('do not produce jwt token for the public app'));
    } else {
      let host = this.getHostname(this.sourceHost);
      if (url) {
        host = this.getHostname(url);
      }
      this.log(1,"getJwtToken: url=" + url + " host=" + host);
      return this.getJwtSK(host).then((sk:string)=>{
        let payload = CryptoJS.enc.Latin1.parse(JSON.stringify({
          "username": this.currentUserData.Username,
          "email": this.currentUserData.Email,
          "RedirectAfterLogin": redirectAfterLogin,
          "exp": String(Math.floor(this.getJwtExpirationTimestamp(10)/1000)), 
          "host": host,
          "jti": this.generateUUID().replace(/\-/g,'')
        }));
        let header = CryptoJS.enc.Latin1.parse(JSON.stringify({
          "typ": "JWT",      
          "alg": "HS256"
        }));
        var token = this.base64url(header) + "." + this.base64url(payload);

        let sig = CryptoJS.HmacSHA256(token, sk);
        let signature = this.base64url(sig);

        //this.log(1,"getJwtToken: signature=" + signature);
        let jwt = token + "." + signature; //encodeURIComponent(header + "." + payload + '.' + signature);
        //this.currentJwtToken = jwt;
        this.log(1,"getJwtToken: jwt=" + jwt);
        return jwt;
      });  
    }
  }

  //https://www.jonathan-petitcolas.com/2014/11/27/creating-json-web-token-in-javascript.html
  public base64url(source) {
    // Encode in classical base64
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
  
    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');
  
    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
  
    return encodedSource;
  }
  

  // GR encoding & is causing problems on c# HtmlDecode
  public HtmlEncode(str) {
    return String(str)
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
            //.replace(/&/g, '&amp;');
  }

  // generate UUID formatted random string taken from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  public generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  // GR. That function was to read images from "assets/img" folder. Doesn't look like it is used
  getAppImageSrc(imageName) {
    let t = this;
    return new Promise(
      function (resolve, reject) {    
        var fullDirectory = t.file.applicationDirectory + 'www/assets/imgs/';
        let src = '';
        if (t.platformIsCordova) {
          t.file.resolveLocalFilesystemUrl(fullDirectory + imageName).then((fe: DirectoryEntry)=>{
            let src = t.normalizeURL2(fe);  
            t.log(0,'getAppImageSrc src=' + src);
            resolve(src);
          }).catch((error)=>{
            t.log(2,'getAppImageSrc can not read image ' + JSON.stringify(error));
            reject(error);
          });
        } else {
          src = './assets/imgs/' + imageName;
          resolve(src);
        }
      }
    )
  }

  getAppVersion() {
    let t = this;
    return new Promise(
      function (resolve, reject) {
        t.log(1,'getAppVersion t.platformIsCordova=' + t.platformIsCordova + ' currentUserData.ActiveAppVersion=' + t.currentUserData.ActiveAppVersion);
        if (t.platformIsCordova) {
          t.appVersion.getVersionNumber().then((v)=>{
            t.log(1,'getAppVersion v=' + v);

            if (v && v != t.currentUserData.ActiveAppVersion) {
              t.currentUserData.appVersionChanged = true;
              let numVer = 0;
              let arrVer = v.split('.');
              for (let n=0; n<arrVer.length; n++) {
                numVer += 100000*parseInt(arrVer[n])/Math.pow(10,3*n);
              }
              t.log(1,"appUpdate trackMatomoEvent: App Update Version value=" + numVer);
              t.trackMatomoEvent("Management", "Update", "App Update Version", numVer);
            }

            t.currentUserData.ActiveAppVersion = v;
            resolve(v);
          }).catch((error)=>{
            t.log(2,'getAppVersion error in getVersionNumber: ' + JSON.stringify(error));
            reject('getAppVersion error=' + JSON.stringify(error));
          });
        } else {
          if (t.WebAppVersion != t.currentUserData.ActiveAppVersion) {
            t.currentUserData.appVersionChanged = true;
            t.currentUserData.ActiveAppVersion = t.WebAppVersion;
          }
          resolve(t.WebAppVersion);
        }
      }
    )
  }

// ====================
// Function:    FormatCurrency
//              Simple currency formatter without 1,000,000 pattern (not true any more)
//
// Input:
//          -   amount - numerical value to display
//          -   currencySign - optional currency sign ($ is default)
//          -   roundAmount - minimal rounding amount
//                  roundAmount = 0.01 - to keep single cents  (default); roundAmount = 0.05 - round to 5 cents
//
// Output:
//          -   formatted currency string ("" for not valid or empty amount)
//
// History:
//          -   2014-12-02 GR transferred from Vinylsolution
//          -   2016-11-09 GR added stripping commas and first "currency" symbol if they exist from original amount before formatting
//          -   2017-05-10 GR added special handling of 0 amount
//          -   2017-06-08 GR added code for "potentially" adding commas
// ====================
FormatCurrency(amount, currencySign = '$', roundAmount: number = 0.01) {
  var s = "", roundTo: number = 0.05, amount2 = amount;
  if (amount2 && isNaN(amount2)) {
      amount2 = amount2.replace(",", "");
      if (amount2 && isNaN(amount2)) {
          amount2 = amount2.substring(1);
      }
  }
  if ((amount2 && !isNaN(amount2)) || 1 * amount2 == 0) {
      if (roundAmount && roundAmount < 1 && !isNaN(roundAmount)) {
          roundTo = roundAmount;
      }
      var i = parseFloat(amount2);
      this.log(0,'FormatCurrency: amount=' + amount + ' amount2=' + amount2 + ' i=' + i);
      var intFactor: number = 1 / roundTo;
      var minus = (i < 0) ? '-' : "";
      i = Math.round(Math.abs(i) * intFactor) / intFactor;  // round value to (roundTo * 100) cents
      s = (i).toLocaleString();
      //this.log(1,'FormatCurrency: 1 s=' + s + ' i=' + i);
      if (s.indexOf('.') < 0) s += ".00";
      //this.log(1,'FormatCurrency: 2 s=' + s );
      if (s.indexOf('.') == (s.length - 2)) s += "0";
      //this.log(1,'FormatCurrency: 3 s=' + s); 
      if (!currencySign) currencySign = "$"; // $ is default currency sign
      // GR 2017-06-08 update s to (1 * s).toLocaleString() for "potentially" adding commas 
      s = minus + currencySign + s;
  }
  return s;
}  

  // format time string originally presented in HH:mm
  formatTime(dateformat: string, timeString: any) {
    var date = moment(timeString, 'HH:mm');
    return moment(date).format(dateformat);
  }

  formatDateTime(dateformat: string, date?: any) {
    if (!date) {
      date = Date.now();
    }
    return moment(date).format(dateformat);
  }

  sameWeek(date1: Date, date2: Date) {
    return moment(date1).isSame(date2,"week");
  }

  sameDay(date1: Date, date2: Date) {
    return moment(date1).isSame(date2,"day");
  }

  // get time difference in hours between 2 dates (first one more recent)
  getDateDiff(date1: Date, date2: Date) {
    var d1 = moment(date1); // newer date
    var d2 = moment(date2); // older date
    var duration = moment.duration(d1.diff(d2));
    return duration.asDays();    
  }
  
  // get time difference in hours between 2 dates (first one more recent)
  getTimeDiffInHours(date1: Date, date2: Date) {
    var d1 = moment(date1); // newer date
    var d2 = moment(date2); // older date
    var duration = moment.duration(d1.diff(d2));
    return duration.asHours();    
  }

  // get time difference in milliseconds between 2 dates (first one more recent)
  getTimeDiffInMilliseconds(date1: Date, date2: Date) {
    var d1 = moment(date1); // newer date
    var d2 = moment(date2); // older date
    var duration = moment.duration(d1.diff(d2));
    return duration.asMilliseconds();    
  }  

  getEndOfWeek(date?: any) {
    if (!date) {
      date = Date.now();
    }
      return moment(date).endOf('week').toDate();  
  }

  addDays(daysToAdd: number, date?: any) {
    if (!date) {
      date = Date.now();
    }
    // moment doesn't work properly with negative numbers !?
    //if (daysToAdd < 0) {
    //  return moment(date).subtract(Math.abs(daysToAdd),'days').toDate();
    //} else {
      return moment(date).add(daysToAdd,'days').toDate();
    //}
  }

  formatDateTime2(dateformat: string, year: any, month: any, date: any) {
    let dateObj = new Date(year, month, date);
    return moment(dateObj).format(dateformat);
  }

  // create expiration timestamp for JWT token ac UTC timestamp minutesToExpiry from now
  getJwtExpirationTimestamp(minutesToExpiry: number) {
    return moment(Date.now()).add(minutesToExpiry,'minutes').utc().valueOf();
  }

  displayServerError(errorText) {
    const alert = this.alertController.create({
      header: 'Submission Errors',
      message: 'Error happened during bid submission: <div>' + errorText + '</div>',
      animated: true,
      cssClass: 'errorBox',
      buttons: ['OK']
    }).then(alert=> {
      alert.present();        
    });
  }

  // get fomatted expiration time as 'current time' + provided period where period
  // must be given in format like '1 hour','2 days' or '3 weeks'
  getExpirationTime(period) {
    let exp = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'); 
    if (period && period.indexOf(' ') > -1) {
      let items = period.split(' ');
      exp = moment(Date.now()).add(items[0],items[1]).format('YYYY-MM-DD HH:mm:ss');
    }
    this.log(1,'getExpirationTime: period=' + period + ' expiration=' + exp);
    return exp;
  }

  // convert given string in datetime object. By default 'YYYY-MM-DD HH:mm' format is expected
  getDateTime(dateTimeString: string, dateTimeFomat?: string) {
    if (!dateTimeFomat) {
      dateTimeFomat = 'YYYY-MM-DD HH:mm';
    }
    return moment(dateTimeString,dateTimeFomat).toDate();    
  }

  // pass page data to setup user Navigation List
  setUserNavigationLists(navList,reset = true) {
    if (reset) this.resetUserNavigationLists();
    if (!this.userNavigationLists['qrqs']) {
      this.userNavigationLists['qrqs'] = [];
    }
    for (let type in navList) {
      let ltype = type.toLocaleLowerCase();
      if (ltype == 'communication') {
        ltype = 'informationrequests';
      }
      if ('quoterequests,quotes,orders,informationrequests'.indexOf(ltype) > -1) {
        for (let i=0; i<navList[type].length; i++) {
          let idp = 'ID';
          if (ltype == 'orders') {
            idp = 'OrderID';
          }
          if (ltype == 'quotes') {
            // add extra navigation for seller
            let item = navList.quotes[i].QuoteRequestID + ';extraid=' + navList.quotes[i].ID;
            if (this.userNavigationLists['qrqs'].length == 0 || this.userNavigationLists['qrqs'].indexOf(item)==-1) this.userNavigationLists['qrqs'].push(item);
          }
          // push ids to the list in the order as they were returned from the server
          // If data is resorted then setUserNavigationLists should be called again
          if (this.userNavigationLists[ltype].indexOf(String(navList[type][i][idp]))==-1) this.userNavigationLists[ltype].push(String(navList[type][i][idp]));
        }
      }
    }
    this.userNavigationListUrl = this.router.url;
    this.log(1,'setUserNavigationLists: userNavigationListUrl=' + this.userNavigationListUrl + ' userNavigationLists=' + JSON.stringify(this.userNavigationLists));
  }

  resetUserNavigationLists() {
    this.userNavigationListUrl = '';
    this.userNavigationLists = {'quoterequests':[],'qrqs':[],'quotes':[],'orders':[],'informationrequests':[]};
  }

  getUserNavigationListUrl() {
    if (this.userNavigationListUrl) {
      if (this.userNavigationListUrl != this.router.url) {
        return this.userNavigationListUrl;
      } else {
        return this.previousUrl;
      }
    } else {
      return '/home';
    }
  }

  getPrevPageId(type, id) {
    let prevId = '';
    let index = -1;
    let list: any = [];
    if (type && id) {
      if (!type.endsWith('s')) {
        type += 's';
      }
      list = this.userNavigationLists[type.toLowerCase()];
      if (list) {
        let len = list.length;
        index = list.indexOf(id);
        if (index > 0 && index < len) {
          prevId = list[index-1];
        }
      }
    }
    this.log(0,'getPrevPageId: type=' + type.toLowerCase() + ' id=' + id + ' index=' + index + ' prevId=' + prevId + ' userNavigationLists=' + JSON.stringify(this.userNavigationLists));
    return prevId;
  }

  getNextPageId(type, id) {
    let nextId = '';
    let list: any = [];
    let index = -1;
    if (type && id) {
      if (!type.endsWith('s')) {
        type += 's';
      }
      let list = this.userNavigationLists[type.toLowerCase()];
      if (list) {
        let len = list.length;
        index = list.indexOf(id);
        if (index > -1 && index < len - 1) {
          nextId = list[index+1];
        }
      }
    }
    return nextId;
  }

  getNumber(val) {
    let num: number = 0;
    if (!isNaN(val)) {
      num = Number(val);
    }
    return num;
  }

  // it expects an argument of array with single entry having summary details about communication
  getQuestionsIcon(communication) {
    let path = '';
    if (communication && communication[0]) {
      let questions: number = Number(communication[0].Questions);
      let questionsViewed: number = Number(communication[0].QuestionsViewed);
      let answers: number = Number(communication[0].Answers);
      let answersViewed: number = Number(communication[0].AnswersViewed);
      // do we want to show when answers were viewed in some cases?
      this.log(0,'getQuestionsIcon: questions=' + questions + ' questionsViewed=' + questionsViewed + 
        ' answers=' + answers + ' answersViewed=' + answersViewed);
      if (answers > 0) {
        path = './assets/imgs/question_answered.png';
      } else if (questionsViewed > 0) {
        path = './assets/imgs/question_read.png';
      } else  if (questions > 0) {
        path = './assets/imgs/question_unread.png';
      } else if (questions <= 0) {
        path = './assets/imgs/question_empty.png';
      }
      this.log(0,'getQuestionsIcon: questions=' + questions + ' questionsViewed=' + questionsViewed + 
        ' answers=' + answers + ' answersViewed=' + answersViewed + ' path=' + path);
    }
    return path; 
  }

  // unlike getQuestionsIcon this function has argument with details about single communication
  getSingleQuestionsIcon(singlecom) {
    let path = '';
    if (singlecom) {

      if (singlecom.Status) {
        if (singlecom.Status == '* Asked Question' || singlecom.Status == 'Answered Question') {
          path = './assets/imgs/question_answered.png'; //green
        } else if (singlecom.Status == '* Viewed Answer' || singlecom.Status == 'Viewed Question') {
          path = './assets/imgs/question_read.png';     //yellow
        } else if (singlecom.Status.indexOf('New') > -1) {
          path = './assets/imgs/question_unread.png';   //red
        }
      } else {   
        if (singlecom.AnsweredByCompanyID) {
          path = './assets/imgs/question_answered.png';
        } else if (singlecom.QuestionRead) {
          path = './assets/imgs/question_read.png';
        } else {
          path = './assets/imgs/question_unread.png';
        }
      }
    }
    return path; 
  }

  // filter array of items (orders,quotes,quoterequests) by matching provided value with
  // values in comma separated list of properties. Note that property names are case sensitive!
  filterItems(existingItems, properties, value) {
    this.log(0,'filterItems: properties=' + properties + ' value=' + value);
    let newItems: any = [];
    if (value) {
      value = value.toLowerCase();
    }
    if (properties) {
      let arrProperties = properties.split(/,\s*/g);
      for (let i=0; i<existingItems.length; i++) {
        let item = existingItems[i];
        let matched = false;
        if (value) {
          for (let j=0; j<arrProperties.length; j++) {
            if (item[arrProperties[j]] && item[arrProperties[j]].toString().toLowerCase().indexOf(value) > -1) {
              matched = true;
            }
            //this.settings.log(1,'filterItems: j=' + j + ' p=' + item[arrProperties[j]] + ' matched=' + matched);
          }
        } else {
          matched = true;
        }
        //this.settings.log(1,'filterItems: i=' + i + ' matched=' + matched);
        if (matched) {
          newItems.push(item);
        }
      }
    } else {
      for (let i=0; i<existingItems.length; i++) {
        newItems.push(existingItems[i]);
      }
    }
    this.log(1,'filterItems: existingItems=' + existingItems.length + ' newItems=' + newItems.length);
    return newItems;
  }

  // sort provided array of items by given property. Note that sortBy variable may have 
  // name like OrderStatus|desc where after pype one can add either asc or desc
  // Also note that property names are case sensitive and if first element of array will not have
  // property then sorting will not happen
  sortItems(items, sortBy) {
    if (items && items[0] && sortBy) {
      this.log(0,'sortItems: value=' + sortBy + ' items.length=' + items.length);   
      let arrSort = sortBy.split(/\s*\|\s*/g);
      let sortProperty = arrSort[0];
      let sortDirection = 'asc';
      if (arrSort.length == 2) {
        sortDirection = arrSort[1].toLowerCase();
      }
      if (items[0][sortProperty]) {
        this.log(1,'sortItems: sortProperty=' + sortProperty + ' exists. Sorting in ' + sortDirection + ' order');   
        if (sortDirection == 'asc') {
          items.sort((a, b) => (a[sortProperty] > b[sortProperty]) ? 1 : -1);
        } else {
          items.sort((a, b) => (a[sortProperty] < b[sortProperty]) ? 1 : -1);
        }
      } else {
        this.log(1,'sortItems: sortProperty=' + sortProperty + ' does not exist!');   
      }
    }
  }

  openInformationRequestLink(ir) {
    let link = '';
    if (ir.QuoteRequestID) {
      if (this.currentUserRole == 'buyer') {
        link = '/quote-request/' + ir.QuoteRequestID + ';a=qa';
      } else {
        link = '/quote-add/' + ir.QuoteRequestID + ';a=qa';
      }
    } else if (ir.QuoteID) {
      link = '/quote/' + ir.QuoteID + ';a=qa';
    } else if (ir.OrderID) {
      link = '/order/' + ir.OrderID + ';a=qa';
    }
    this.openPage(link);
  }

  // returns formatted "time before expiration"
  getTimeBeforeExpiration(expirationDateTime, expiredMessage='Closed', numbers=3, label='short') {
    let timeDiff = '';
    if (expirationDateTime) {
      timeDiff = this.getTimeDifference(expirationDateTime, this.formatDateTime('YYYY-MM-DD HH:mm:ss'), numbers, label);
      if (!timeDiff || timeDiff.indexOf('-') == 0) {
        timeDiff = expiredMessage;
      }
    }
    return timeDiff;
  }

  // returns formatted "time difference between start and end time
  // when numbers=3 (d 'd' hh:mm), 2 (d 'd' h 'h') or 1 (d 'd') format
  getTimeDifference(endDateTime, startDateTime, numbers=3, label='short') {
    let d = 'd';
    let h = 'h';
    let m = 'm'
    let s = 's';
    let d1 = 'd';
    let h1 = 'h';
    let m1 = 'm'
    let s1 = 's';
    if (label == 'long') {
      d = ' days';
      h = ' hours';
      m = ' min';
      s = ' sec';
      d1 = ' day';
      h1 = ' hour';
      m1 = ' min';
      s1 = ' sec';
    }
    let timeDiff = '';
    let sign = '';
    let timeBefore: any = '';
    this.log(0,'getTimeDifference: endDateTime=' + endDateTime + ' startDateTime=' + startDateTime + ' numbers=' + numbers);
    if (endDateTime && startDateTime) {
      let start = moment(startDateTime);
      var end = moment(endDateTime);
      if (end < start) {
        sign = '-';
        let t = startDateTime;
        startDateTime = endDateTime;
        endDateTime = t;
      } 
      timeBefore = moment.duration(end.diff(start)); 
      let timeD = this.getTimeBit(timeBefore.days(), d1, d);
      let timeH = this.getTimeBit(timeBefore.hours(), h1, h);
      let timeM = this.getTimeBit(timeBefore.minutes(), m1, m);
      let timeS = this.getTimeBit(timeBefore.seconds(), s1, s);      
      if (numbers == 1) {
        if (timeBefore.hours() > 0) {
          timeDiff = timeH;
        } else if (timeBefore.minutes() > 0) {
          timeDiff = timeM;
        } else {
          timeDiff = timeS;
        }
      } else if (numbers == 2) {
        if (timeBefore.hours() > 0) {
          timeDiff = timeH + ' ' + timeM;
        } else if (timeBefore.minutes() > 0) {
          timeDiff = timeM + ' ' + timeS;
        } else {
          timeDiff = timeS;
        }
      } else {
        timeDiff = (100 + timeBefore.hours() + '').slice(-2) + ':' + (100 + timeBefore.minutes() + '').slice(-2);
      }
      if (timeBefore.days() > 0) {
        if (numbers == 1) {
          timeDiff = timeD;
        } else if (numbers == 2) {
          timeDiff = timeD + ' ' + timeH;
        } else {
          timeDiff = timeD + ' ' + timeDiff;
        }
      }  
    }
    this.log(0,'getTimeDifference: timeDiff=' + timeDiff + ' days=' + timeBefore.days());
    return timeDiff;  
  }

  getTimeBit(timeVal,singleLabel,multiLabel) {
    let bit = '';
    if (timeVal == 1) {
      bit = timeVal + singleLabel;
    } else if (timeVal > 1) {
      bit = timeVal + multiLabel;
    }
    return bit;
  }

  // create JSON string with app & user & device information to provide e.g. for error reports
  getUserAndDeviceInfoJson() {
    let udInfo = [];
    udInfo.push({"name":"Username","value":this.currentUserData.Username});
    udInfo.push({"name":"User ID","value":this.currentUserData.Userid});
    udInfo.push({"name":"User Full Name","value":this.currentUserData.Firstname + " " + this.currentUserData.Lastname});
    udInfo.push({"name":"User Email","value":this.currentUserData.Email});
    udInfo.push({"name":"App Name","value":this.appsettings.appName});
    udInfo.push({"name":"Active App Version","value":this.currentUserData.ActiveAppVersion});
    udInfo.push({"name":"Touch Id Enabled","value":this.currentUserData.TouchIdEnabled});
    udInfo.push({"name":"Calendar Integration Enabled","value":this.currentUserData.RosterSettings.CalendarIntegrationEnabled});
    udInfo.push({"name":"Device Manufacturer","value":this.currentUserData.DeviceManufacturer});
    udInfo.push({"name":"Device Platform","value":this.currentUserData.DevicePlatform});
    udInfo.push({"name":"Device Version","value":this.currentUserData.DeviceVersion});
    udInfo.push({"name":"Device Model","value":this.currentUserData.DeviceModel});
    udInfo.push({"name":"Device Token","value":this.deviceToken});
    udInfo.push({"name":"Device Id","value":this.deviceId});
    udInfo.push({"name":"Web Site","value":this.sourceHost});
    udInfo.push({"name":"App TextId","value":this.appTextId});
    udInfo.push({"name":"Matomo Url","value":this.matomoUrl});   
    udInfo.push({"name":"Track Matomo Analytics","value":this.trackMatomoAnalytics});   
    udInfo.push({"name":"Matomo Site Id","value":this.matomoSiteId});   
    udInfo.push({"name":"Update Interval in minutes","value":this.checkForUpdatesTimeInMinutes});   
    let info = {};
    info["data"] = udInfo;
    return JSON.stringify(info);
  }  
}

// Class to get data for auto updated count down clock. 
// One can either display clock.formattedCountDown or use other properties like clock.hours, clock.minutes ...
// if more control is required. Simplest usage looks like that:
//    clock = countdownClock(endTime);
//    clock.start();
//    clock.stop(); 
// Note that clock will stop updating when time reaches endTime (and display special expiredText in that case)
// Clock also has 2 modes: "normal" when clock is updated every minute and clock.formattedCountDown is not showing seconds 
// and "verbose" (last 30 minutes by default) when clock updates every second.
// One can also pass functions which will be run on start on verbose period (onVerbosePeriodStart) and on expiry (onExpiry)
export class CountdownClock {
	public expiredText: string = 'Closed';        // text which formattedCountDown will have after expiration
	public verbosePeriodInMinutes: number = 30;   // period when clock will be updated each second instead of each minute
	public dayLabel = 'd';
	public verbosePeriod: boolean = false;
	public total: number = 0;
	public days: number = 0;
	public hours: number = 0;
	public minutes: number = 0;
	public seconds: number = 0;
	public formattedCountDown: string = '';
	
	private clockIsStopped: boolean = true;
	private firstRun: boolean = true;

  // endTime is a datetime string (in yyyy-mm-dd HH:MM:SS format)
  constructor(private endTime) {
		console.log('countdownClock: endTime=' + endTime);
	}
	
 	start() {
		this.clockIsStopped = false;
		this.updateClock(this);
	}
	
	stop() {
		this.clockIsStopped = true;
	}
	
	// on can pass a function which will run when countdown gets to 0
	public onExpiry: any = null;

	// on can pass a function which will run when verbose period starts
	public onVerbosePeriodStart: any = null;

	private pad0(num, len) {
		return(1e15 + num + '').slice(-len);
	}
	
	private updateClock(t) {
		//console.log('updateClock: t.endTime=' + t.endTime + ' t.clockIsStopped=' + t.clockIsStopped + ' t.firstRun=' + t.firstRun);
		if (t.endTime) {
		  t.total = Date.parse(t.endTime) - Date.now();
		  t.seconds = Math.floor((t.total / 1000) % 60);
		  t.minutes = Math.floor((t.total / 1000 / 60) % 60);
		  t.hours = Math.floor((t.total / (1000 * 60 * 60)) % 24);
		  t.days = Math.floor(t.total / (1000 * 60 * 60 * 24));
		  if (t.total <= 0) {
        // to stop rising event when clock was already started after expiration time
        if (t.onExpiry && typeof t.onExpiry == 'function' && !t.firstRun) {
          console.log('CountdownClock: before onExpiry');
          t.onExpiry();
        }
        t.formattedCountDown = t.expiredText;
        t.clockIsStopped = true;
		  }
		  if (!t.clockIsStopped) {
        if ( (t.total /1000) < t.verbosePeriodInMinutes*60 ) {
          if (t.onVerbosePeriodStart && typeof t.onVerbosePeriodStart == 'function' && !t.firstRun && !t.verbosePeriod) {
            console.log('CountdownClock: before onVerbosePeriodStart');
            t.onVerbosePeriodStart();
          }
          t.verbosePeriod = true;
        } else {
          t.verbosePeriod = false;
        }
        let interval = 60*1000; // "default" update interval is one minute
        t.formattedCountDown = '';  
        let dayLabel = t.dayLabel;      
        if (t.days == 1) {
          if (dayLabel.length > 1 && dayLabel.endsWith('s')) {
            dayLabel = dayLabel.slice(0, -1);
          }
          t.formattedCountDown += t.days + dayLabel;
        } else if (t.days > 0) {
          if (dayLabel.length > 1 && !dayLabel.endsWith('s')) {
            dayLabel = dayLabel + 's';
          }
          t.formattedCountDown += t.days + dayLabel;
        }
        t.formattedCountDown += ' ' + t.pad0(t.hours,2) + ':' + t.pad0(t.minutes,2);

        if (t.verbosePeriod) {
          interval = 1000;
          t.formattedCountDown += ':' + t.pad0(t.seconds,2);
        }
        t.firstRun = false;
        //console.log('updateClock: t.endTime=' + t.endTime + ' interval=' + interval + ' t.formattedCountDown=' + t.formattedCountDown);
        setTimeout(() => {t.updateClock(t)}, interval);
		  }
		}
	}
}

// **** Class used to fire events when value was set or changed somewhere else
// **** Example of usage is below. Functionally similar to ExternalProcess but
// **** syntax is different and multiple onChange/onSet "events"
// **** can be added and removed
// *********************************************************************************
// var m = new moitoredValue(55);
// var key = m.onChange(v=>{
//	 console.log("monitored value changed to " + v);
// });
// m.onSet(v=>{
//	 console.log("monitored value set to " + v);
//   m.stopFurtherOnSet();  // Further onSet (if they exist) will not run
// });
// m.value = 55; // fires onSet but not onChange
// m.value = 15; // fires onSet and onChange. 
// m.dropOnChange(key); // removed above onChange event. It will not be called on further changes
export class monitoredValue {
  private _value = null;
  private _onSets: any = {};
  private _onChanges: any = {};
  private _onChangeStopped: boolean = false;
  private _onSetStopped: boolean = false;
  
  constructor(val) {
    this._value = val;
  }

  // get value
  get value() {
    return this._value;    
  }
  
  // set value and execute corresponding events
  set value(val) {
    var prevValue = this._value;
    this._value = val;
      for(var key in this._onSets) {
        if (!this._onSetStopped) {
          var onSet: any = this._onSets[key];
          if (typeof onSet == 'function') {
            onSet(val);
          }
        }
      }
      this._onSetStopped = false;
      for(var key in this._onChanges) {
        if (!this._onChangeStopped) {
          var onChange: any = this._onChanges[key];
          if (prevValue != val && typeof onChange == 'function') {
            onChange(val);
          }
        }
      }
      this._onChangeStopped = false;
  }

  // remove all events
  clearEvents() {
    this._onChanges = {};
    this._onChangeStopped = false;
    this._onSets = {};
    this._onSetStopped = false;
  }

  // to drop given onChange function so that it will not be called for any future events
  dropOnChange(key) {
    delete this._onChanges[key];
  }
  
  // to drop given onSet function so that it will not be called for any future events
  dropOnSet(key) {
    delete this._onSets[key];
  }

  // to stop executing later onChange functions for one particular value change
  stopFurtherOnChange() {
    this._onChangeStopped = true;
  }
  
  // to stop executing later onSet functions for one particular value set
  stopFurtherOnSet() {
    this._onSetStopped = true;
  }
  
  // execute provided function when value changes. It returns a key which can be used to drop onChange event later
  onChange(func: any) {
    let key = this.generateUUID();
    this._onChanges[key] = func;
    return key;
  } 
  
  // execute provided function when new value is set doesn't matter if it changed or not
  onSet(func: any) {
    let key = this.generateUUID();
    this._onSets[key] = func;
    return key;
  } 

  // generate UUID formatted random string taken from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  private generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

}

export class ExternalProcess {
  private _isRunning: boolean = false;
  private _listener(value: boolean) {
    //console.log("ExternalProcess _listener value=" + value);
  }
  get isRunning() {
    return this._isRunning;    
  };
  set isRunning(value: boolean) {
    this._isRunning = value;
    //console.log("ExternalProcess: set isRunning to " + value);
    this._listener(value);
  };
  registerListener(listener: any) {
    //console.log("ExternalProcess: registerListener listener" + listener);
    this._listener = listener;
  }  
}

export class Result {
  public "Success": boolean = false; 
  public "Message": string = ""; 
  public "Data": any = {};
};

export class UserData {
  public Firstname: string = "";
  public Lastname: string = "";
  public Username: string = "";
  public Userid: string = "";
  public Email: string = "";

  public SessionId: string = "";
  public CompanyId: string = "";
  public CompanyName: string = "";
  public ABN: string = "";
  public CompanyLogo: string = "";
  public CompanyAddress: string = "";
  public CompanyCity: string = "";
  public CompanyPostcode: string = "";
  public CompanyState: string = "";
  public CompanyCountry: string = "";
  public CompanyPhone: string = "";
  public CompanyEmail: string = "";
  public capRole: string = "";
  //public capExtras: string = "";
  public capPromoted: boolean = false;
  public capPublicUser: boolean = false;
  public capDatalookup: boolean = false;
  public capConditions: string = "";
  public capMakes: string = "";
  public capContactAfterAuction: string = "";
  public capCompanyRating: string = "";
  public capCompanyDisplayName: string = "";
  public capCompanyInfo: string = "";
  public capFeatureImage: string = "";
  public capImages: string = "";
  public capMemberSince: string = "";
  public capPurchases: string = "";
  public capSales: string = "";
  public capCancellations: string = "";
  public capAverageReplyTime: string = "";

  public UserDataLastUpdated: Date;

  public ActiveAppVersion: string = "0.0.0";
  public appVersionChanged: boolean = false;
  public TouchIdEnabled: boolean = false;
  public RosterSettings: RosterSettings = new RosterSettings();
  public SubsribedToFirebaseTopics: string[] = [];
  public DeviceManufacturer: string = "";
  public DevicePlatform: string = "";
  public DeviceVersion: string = "";
  public DeviceModel: string = "";
}

export class RosterSettings {
  public CalendarIntegrationEnabled = false;
  public FirstReminderHoursBeforeStart = 24;  
  public SecondReminderHoursBeforeStart = 2;
  public ShiftStartEndTimes: Array<RosterTimes> = [];  
}

export class RosterTimes {
  public startTime: string = "00:00";
  public endTime: string = "00:00";
}